import { Button } from '@blueprintjs/core'
import React from 'react'

export default function ButtonGroup({ disabled, onChange, selectedValue, options }) {
  const getIcon = (value) => {
    switch (value) {
      case 'Y':
        return 'tick'
      case 'N':
        return 'cross'
      case 'U':
        return 'help'
      default:
        break
    }
  }
  const renderOptions = () => {
    return options.map((property, index) => {
      return (
        <Button
          icon={getIcon(property[0])}
          key={property[0]}
          text={property[1]}
          disabled={Array.isArray(disabled) ? disabled[index] : disabled}
          onClick={() => onChange(property[0])}
          intent={selectedValue === property[0] ? 'primary' : null}
          className={selectedValue === property[0] ? 'selected' : ''}
          outlined
          large
        />
      )
    })
  }

  return <div className="buttonGroupContainer">{renderOptions()}</div>
}
