/* eslint-disable prefer-promise-reject-errors */
import request from 'superagent'
import errors from '../data/errors.json'
import { getErrorMessage } from './helpers'
import history from './history'

const UNAUTHORIZED = 'Unauthorized'

const SESSION_ERROR_MESSAGE = errors.SESSION_ERROR_MESSAGE

const clearLoading = (isLoading, loadingState, loadingType) => {
  // The param should be a callback because clearLoading is used as a callback
  // in the same Promise scope.
  isLoading(() => ({ ...loadingState, [loadingType]: false }))
}

/**
 * Check the internet connection for the HTTP requests.
 * Redirect to the error page only when no internet connection and session id,
 * 'Unauthorized' have trouble.
 */
export const navigateToErrorScreen = async (
  errReference,
  redirectPage = false,
  loading = undefined,
) => {
  const { isLoading = false, loadingState, loadingType } = loading || {}

  // Not only when the internet disconnected,
  // but also when the session id is expired,
  // we need to redirect the page.
  if (!window.navigator.onLine || redirectPage) {
    let errs = []

    if (!window.navigator.onLine) {
      errs = errors.NETWORK_DISCONNECT
    } else if (
      getErrorMessage(errReference) === 'URLDecoder: Incomplete trailing escape (%) pattern'
    ) {
      errs = errors.URL_TYPO
    } else if (getErrorMessage(errReference) === 'Access token is already submitted') {
      errs = errors.SUBMITTED
    } else if (Array.isArray(errReference.message)) {
      errs = errors.SESSION_ERROR_MESSAGE
    } else {
      errs = [getErrorMessage(errReference)]
    }

    await history.push('/message', { errs })

    isLoading && clearLoading(isLoading, loadingState, loadingType)
  }

  throw new Error(errReference)
}

export const loadFromBackend = async ({
  setState,
  metrics,
  setLocalization,
  setThankYou,
  setAuthenticated,
  isAuthenticated,
}) => {
  return new Promise(() => {
    request
      .get('/api/v1/questionnaire/current-session')
      .accept('json')
      .then(async (res) => {
        if (res.unauthorized) {
          await navigateToErrorScreen({ message: SESSION_ERROR_MESSAGE }, true)
        }

        if (!isAuthenticated) {
          setAuthenticated(true)
        }

        return res
      })
      .then((res) => {
        if (res.body) {
          // Do update after authenticated is updated to true
          if (!res.body.pedigree.persons[res.body.pedigree.probandId].relationshipToProband) {
            res.body.pedigree.persons[res.body.pedigree.probandId].relationshipToProband = 'proband'
          }

          setState(() => ({
            isMetricMeasurementSystem: !!res.body.isMetricMeasurementSystem,
            countryCode: res.body.countryCode,
            questionnaireType: res.body.type,
            notes: res.body.notes,
            ...res.body.pedigree,
          }))
        }
      })
      .then(() => {
        const requests = [
          request
            .get('/api/v1/config/localization')
            .then((res) => {
              if (!res.ok) {
                throw new Error('Unable to get localization')
              }

              return res
            })
            .then((res) => {
              if (res.body) {
                setLocalization(res.body)
              }
            })
            .catch(async (err) => {
              const isSessionError = err.response?.body.error === UNAUTHORIZED
              await navigateToErrorScreen(
                isSessionError ? { message: SESSION_ERROR_MESSAGE } : err,
                isSessionError,
              )
            }),
          request
            .get('/api/v1/config/page/thank-you')
            .accept('json')
            .then((fetched) => {
              if (!fetched.ok) {
                throw new Error('Unable to get thank you url.')
              }

              return fetched
            })
            .then((fetched) => {
              if (fetched.body) {
                setThankYou(fetched.body['thank-you'])
              }
            })
            .catch(async (err) => {
              const isSessionError = err.response?.body.error === UNAUTHORIZED
              await navigateToErrorScreen(
                isSessionError ? { message: SESSION_ERROR_MESSAGE } : err,
                isSessionError,
              )
            }),
        ]

        Promise.all(requests)
      })
      .catch(async (err) => {
        // Without any conditions, it should redirect to the error page.
        // No matter of what kind of error, it should redirect to the error page
        // because it is called only while the app is loaded for the first time.
        setAuthenticated(() => false)
        const isSessionError = err.response?.body.error === UNAUTHORIZED
        await navigateToErrorScreen(isSessionError ? { message: SESSION_ERROR_MESSAGE } : err, true)
      })
      .finally(() => {
        saveMetricToBackend(metrics)
      })
  })
}

const saveToBackend = (
  state,
  setState,
  isLoading,
  setToasts,
  onSuccessfulSave,
  loadingState,
  loadingType,
  // should set default value
  // because the followings are used only for `logout` callback.
  setLoggingIn = undefined,
  setAuthenticated = undefined,
  // It is only for `Submit` button.
  finalSubmission = false,
) => {
  return new Promise((resolve, reject) => {
    // Use a callback because isLoading is an async function and it is invoked
    // twice in this promise object.
    isLoading(() => ({ ...loadingState, [loadingType]: true }))

    request
      .get('/api/v1/questionnaire/current-session')
      .accept('json')
      .then(async (fetched) => {
        if (fetched.unauthorized) {
          await navigateToErrorScreen({ message: SESSION_ERROR_MESSAGE }, true)
        }

        return fetched
      })
      .then((fetched) => {
        if (fetched.body) {
          fetched.body.pedigree = state
          fetched.body.notes = state.notes
          fetched.body.isMetricMeasurementSystem = state.isMetricMeasurementSystem
        }

        request
          .put('/api/v1/questionnaire/current-session')
          .accept('json')
          .send(fetched.body)
          .then((updated) => {
            if (!updated.ok) {
              throw new Error('Unable to update the current session.')
            }

            if (updated.body) {
              setState(() => ({
                ...updated.body.pedigree,
                isMetricMeasurementSystem: !!updated.body.isMetricMeasurementSystem,
                questionnaireType: updated.body.type,
                metrics: state.metrics,
                notes: updated.body.notes,
                countryCode: state.countryCode,
              }))
            }

            onSuccessfulSave(
              isLoading,
              setToasts,
              loadingState,
              loadingType,
              setLoggingIn,
              setAuthenticated,
            )
              .then((res) => {
                resolve(res)
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch(async (err) => {
            if (err.response && err.response.body && err.response.body.status === 401) {
              const isSessionError = err.response?.body.error === UNAUTHORIZED
              await navigateToErrorScreen(
                isSessionError ? { message: SESSION_ERROR_MESSAGE } : err,
                isSessionError,
                {
                  isLoading,
                  loadingState,
                  loadingType,
                },
              )
            }

            clearLoading(isLoading, loadingState, loadingType)
            reject(err.response && err.response.body ? err.response.body : err)
          })
          .finally(async () => {
            // This function could be in Navigation component.
            // However, the function call should be just before
            // submit function call which is `onSuccessfulSave`
            // because the authorization does not work after `onSuccessfulSaver`
            if (finalSubmission) {
              await saveMetricToBackend(state.metrics)
            }
          })
        // }
      })
      .catch(async (err) => {
        const isSessionError = err.response?.body.error === UNAUTHORIZED
        await navigateToErrorScreen(
          isSessionError ? { message: SESSION_ERROR_MESSAGE } : err,
          isSessionError,
          {
            isLoading,
            loadingState,
            loadingType,
          },
        )
      })
  })
}

/**
 * It is for saving metric data to the backend. The entire application must work
 * even with the error in this HTTP request. For this reason, it does not take care of
 * the error controls.
 *
 * @param {Object} metricState It includes the metric attributes only.
 * @param {Function} redirect A function that `<a />` tag's `click` event.
 * @returns {Promise<undefined>} Promises sending metric data without `resolve` and `reject`.
 */
export const saveMetricToBackend = async (
  metricState,
  backButton = false,
  redirect = undefined,
) => {
  const requests = [
    // Session is only for the Back button
    // because the Backend requires time info.
    backButton
      ? request
          .get('/api/v1/questionnaire/current-session')
          .accept('json')
          // Nothing to do
          .then(() => {})
      : undefined,

    request
      .post('/api/v1/questionnaire-metrics')
      .accept('json')
      .send({ data: JSON.stringify(metricState) })
      // Nothing to do
      .then(() => {})
      .finally(() => {
        if (redirect) redirect()
      }),
  ]

  return Promise.all(requests)
}

export const submitData = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    request
      .post(`/api/v1/questionnaire/current-session/submit`)
      .accept('json')
      .then((result) => {
        if (!result.ok) {
          throw new Error('Unable to submit the updated data.')
        }

        return result
      })
      .then((result) => {
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('success', 'primary', 'Successfully Submitted')
        resolve('Submit Response: ' + result)
      })
      .catch(async (err) => {
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject('Submit Error: ' + err)
      })
  })
}

export const logout = (
  isLoading,
  setToasts,
  loadingState,
  loadingType,
  setLoggingIn,
  setAuthenticated,
) => {
  return new Promise((resolve, reject) => {
    callLogout(
      (res) => {
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('success', 'primary', 'Successfully Logged Out')
        setLoggingIn(true)
        setAuthenticated(false)

        history.push('/user-logout', {
          message: 'You have logged out. Please login again to restart the questionnaire.',
        })

        resolve('Log Out Response:' + res)
      },
      (err) => {
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject('Log Out Error:' + err)
      },
    )
  })
}

export const callLogout = (success, failure) => {
  request
    .post(`/logout`)
    .then((res) => success(res))
    .catch(async (err) => {
      const isSessionError = err.response?.body.error === UNAUTHORIZED
      await navigateToErrorScreen(
        isSessionError ? { message: SESSION_ERROR_MESSAGE } : err,
        isSessionError,
      )
      failure(err)
    })
}

export const login = (success, failure, redirect = undefined, token = '') => {
  return new Promise(() => {
    request
      .get(`/api/v1/login/?accessToken=${token}`)
      .then(async (res) => {
        if (res.unauthorized) {
          await navigateToErrorScreen({ message: SESSION_ERROR_MESSAGE }, true)
        }
      })
      .then(() => {
        success()
      })
      // Please redirect to /Questionnaire
      // after all the authentication processes are done
      // Please do not mix up setAuthentication and redirect.
      .then(() => {
        if (redirect) {
          redirect()
        }
      })
      .catch(async (err) => {
        failure(err)
        await navigateToErrorScreen(err, true)
      })
  })
}

export const finishSave = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve) => {
    clearLoading(isLoading, loadingState, loadingType)
    setToasts('save', 'success', 'Form Responses Saved')
    resolve('save success - Form Responses Saved')
  })
}

export const resetForm = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve) => {
    clearLoading(isLoading, loadingState, loadingType)
    setToasts(
      'reset',
      'success',
      'Form Responses Reset. You must manually remove patients from the PhenoTips dashboard.',
    )
    resolve('reset successful')
  })
}

export const downloadPDF = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    request
      .get(`/api/v1/questionnaire/current-session/export/pdf`)
      .accept('application/octet-stream')
      .set('Authorization', 'Basic QWRtaW46YWRtaW4=')
      .type('application/json')
      .responseType('arraybuffer')
      .responseType('blob')
      .then((res) => {
        const file = new Blob([res.body], { type: 'application/pdf' })
        const fileName = res.header['content-disposition'].split('"')[1]
        // For MS Edge and IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, fileName)
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = URL.createObjectURL(file)
          // The object. It refers only the memory location.
          const link = document.createElement('a')
          link.href = objUrl
          link.download = res.header['content-disposition'].split('"')[1]
          link.click()

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            URL.revokeObjectURL(objUrl)
          }, 100)
        }
        clearLoading(isLoading, loadingState, loadingType)
        resolve(res.body)
      })
      .catch((err) => {
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject(err)
      })
  })
}

export default saveToBackend
