import { useEffect } from 'react'

const RedirectHooks = ({ setLoggingIn, loggingIn, history }) => {
  useEffect(() => {
    if (loggingIn) {
      setLoggingIn(() => false)
    }

    history.push('/questionnaire')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export default RedirectHooks
