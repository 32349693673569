import React, { Component } from 'react'

class ConclusionStep extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="bodyText">
        <p>
          Please click Submit questionnaire when you are complete. The information you have provided
          will guide us in your assessment.
          <br />
          <br />
          Once the questionnaire is submitted, you will not be able to make any changes to your
          responses. If you have any questions about the information in this questionnaire, please
          speak with your genetics provider.
        </p>
        {/* TODO - Move the following information to the final page and some sort of info bar */}
        {/* <p>If you have any questions about the information in 
        this survey or wish to speak with us further, please email us 
        at icvd@gosh.nhs.uk or over the phone at 020 7405 9200 ext. 5124, 5305, and 5139.</p> */}
      </div>
    )
  }
}

export default ConclusionStep
