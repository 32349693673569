import { Button, Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import React, { useState } from 'react'
import ConditionIndicators from './ConditionIndicators'

const IS_CONDITION_INDICATOR_VISIBLE = false

export default function InternalCard({
  fullName,
  relationship,
  removePersonEntry,
  children,
  medicalConditions,
  opened,
}) {
  const [entryRendererOpen, toggleEntryRenderer] = useState(opened)

  return (
    <div className="person-entry">
      <div
        className={classNames(
          'cardTitle',
          'cardTitle-white',
          'cardTitle-left',
          'cardTitle-clickable',
          { 'card-open': entryRendererOpen },
        )}
        onClick={() => toggleEntryRenderer(!entryRendererOpen)}
      >
        <div className="d-flex align-items-center">
          <div className="person-entry-info">
            <h4>{fullName}</h4>
            <p className="subLabel">{relationship}</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {IS_CONDITION_INDICATOR_VISIBLE && (
            <ConditionIndicators medicalConditions={medicalConditions} />
          )}
          <Button
            rightIcon="trash"
            className={entryRendererOpen ? 'cardRemoveHide' : 'cardRemoveShow'}
            intent="danger"
            minimal={true}
            onClick={(e) => {
              e.stopPropagation()
              removePersonEntry()
            }}
          />
          <Icon
            icon={entryRendererOpen ? 'chevron-up' : 'chevron-down'}
            className="cardArrow"
            color="#0C426A"
            iconSize="20"
          />
        </div>
      </div>
      {entryRendererOpen ? <div className="cardBody cardBody-inner">{children}</div> : null}
    </div>
  )
}
