import React, { useContext } from 'react'
import AuthHook from '../hooks/AuthHooks'
import RedirectHooks from '../hooks/RedirectHooks'
import Context from '../utils/context/Context'
import Spinner from '../widgets/Spinner'

const Auth = ({ history }) => {
  const { token, setAuthenticated, setLoggingIn, setToken, getErrorMessage, loggingIn } =
    useContext(Context)

  if (token) {
    AuthHook({
      token,
      setAuthenticated,
      setLoggingIn,
      setToken,
      history,
      getErrorMessage,
    })
  } else {
    RedirectHooks({
      setLoggingIn,
      loggingIn,
      history,
    })
  }

  return <Spinner spinnerMessage="Loading the application..." />
}

export default Auth
