import _ from 'lodash'
import React, { Component } from 'react'

import FamilyHelper from '../utils/FamilyHelper.js'
import { getParentStr, getParentSex, getOffspringStr, getSiblingStr } from '../utils/helpers.js'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'

import FullName from '../widgets/FullName'
import LifeStatus from '../widgets/LifeStatus'
import MultiPersonEntry from '../widgets/MultiPersonEntry'

const { ARE_ALL_MATERNAL_COUSINS_ALIVE, ARE_ALL_PATERNAL_COUSINS_ALIVE, SEX, FIRST_NAME } =
  PersonFieldPaths

class CousinInfoStep extends Component {
  constructor(props) {
    super(props)

    this.familyHelperNoCreate = new FamilyHelper(props.stateManager.cpt.state)

    this.siblingTypes = [
      {
        sex: 'M',
        fullOrHalf: 'full',
      },
      {
        sex: 'M',
        fullOrHalf: 'half',
      },
      {
        sex: 'F',
        fullOrHalf: 'full',
      },
      {
        sex: 'F',
        fullOrHalf: 'half',
      },
    ]
  }

  render() {
    // ID of patient's mother or father.
    const patientParentId = this.getParentPersonId(this.familyHelperNoCreate, false)
    // All of the patient's parent cousins
    const cousinPersonIds = this.getCousinPersonIds(this.props.person.id).map((cousinPersonId) => {
      const { sex = '' } = this.props.stateManager.cpt.state.persons[cousinPersonId]

      return {
        id: cousinPersonId,
        sex,
      }
    })

    // List of the siblings of the patient's parent
    const parentSiblings = this.siblingTypes.map(({ sex, fullOrHalf }) =>
      Object.keys(this.familyHelperNoCreate.getSiblings(patientParentId, sex, fullOrHalf)),
    )

    // Memo of the grouping per patient's parent sibling
    const groupedCousins = {}

    // Grouping per patient's parent sibling
    cousinPersonIds.forEach(({ id, sex }) => {
      this.familyHelperNoCreate.getRelationships(id).forEach(({ source }) => {
        if (parentSiblings.flat().includes(source)) {
          if (!groupedCousins[source]) {
            groupedCousins[source] = [{ id, sex }]
          } else {
            groupedCousins[source].push({ id, sex })
          }
        }
      })
    })

    // Sort by sex
    const cousinSortUponSex = Object.values(groupedCousins)
      .map((cousins) => cousins.sort((a, b) => a.sex.localeCompare(b.sex)))
      .flat()
      .map(({ id }) => id)

    return (
      <React.Fragment>
        <div className="mainLabel">
          <p>
            For each of the patient&apos;s cousins on their {getParentStr(this.props.side)}&apos;s
            side, please add their information if they are deceased
          </p>
        </div>
        <MultiPersonEntry
          personIds={cousinSortUponSex}
          entryRenderer={this.renderCousinEntry}
          personLabel={this.getCousinPersonLabel}
          stateManager={this.props.stateManager}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
        />
      </React.Fragment>
    )
  }

  renderCousinEntry = (cousinPersonId) => {
    return (
      <div key={cousinPersonId}>
        <FullName
          id={cousinPersonId}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
          personIdWithCreate={_.partial(this.resolveCousinPersonIdWithCreate, cousinPersonId)}
        />
        <LifeStatus
          personId={cousinPersonId}
          personIdWithCreate={_.partial(this.resolveCousinPersonIdWithCreate, cousinPersonId)}
          stateManager={this.props.stateManager}
        />
      </div>
    )
  }

  handleQuestionPropChange = (e) => {
    const lifeStatusFieldPath = {
      maternal: ARE_ALL_MATERNAL_COUSINS_ALIVE,
      paternal: ARE_ALL_PATERNAL_COUSINS_ALIVE,
    }

    this.props.stateManager.setPersonValue(
      this.props.person.id,
      lifeStatusFieldPath[this.props.side],
      e.target.value,
    )
  }

  getCousinPersonIds = (id) => {
    const cousins = this.props.stateManager
      .getFamilyHelper()
      .getFirstCousins(id, getParentSex(this.props.side))

    return _.map(cousins, (cousin) => cousin.id)
  }

  getCousinPersonLabel = (personId) => {
    const cousinRelStr = getOffspringStr(this.props.stateManager.getPersonValue(personId, SEX))
    const uncleIds = ['M', 'F']
      .map((sex) => this.familyHelperNoCreate.resolveParent(personId, sex, false))
      .filter((person) => person.id)
    if (uncleIds > 1) {
      throw Error()
      // TODO - deal with the case of knowing more than one parent
      // - ie consanguinity or a more complete family tree.
    }
    const uncleName = this.props.stateManager.getPersonValue(uncleIds[0].id, FIRST_NAME)
    const uncleRelStr = getSiblingStr(this.props.stateManager.getPersonValue(uncleIds[0].id, SEX))
    const parentId = this.getParentPersonId(this.familyHelperNoCreate, false)
    const parentFirstName = this.props.stateManager.getPersonValue(parentId, FIRST_NAME)
    const parentsLabel = parentFirstName || getParentStr(this.props.side)

    return uncleName
      ? `${uncleName}'s ${cousinRelStr}`
      : `${parentsLabel}'s ${uncleRelStr}'s ${cousinRelStr}`
  }

  // TODO: never gets called because there is no situation in which the cousin
  // does not exist when they have info being filled out
  resolveCousinPersonIdWithCreate = (cousinPersonId, state) => {
    const familyHelper = new FamilyHelper(state)
    if (!familyHelper.doesPersonExist(cousinPersonId)) {
      familyHelper.addCousin(this.getParentPersonId(familyHelper), cousinPersonId)
    }

    return cousinPersonId
  }

  getParentPersonId = (familyHelper, create = true) => {
    const parentPerson = familyHelper.resolveParent(
      this.props.person.id,
      getParentSex(this.props.side),
      create,
    )

    return _.get(parentPerson, 'id')
  }
}

export default CousinInfoStep
