import React, { useContext } from 'react'
import { TypeContext } from '../pages/Questionnaire'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'

function IntroductionStep() {
  const { localization } = useContext(Context)
  const path = 'questionnaire.introduction.cancer'
  const isCancerQuestionnaire = useContext(TypeContext) === 'cancer'

  return (
    <div className="bodyText">
      {isCancerQuestionnaire ? (
        <p dangerouslySetInnerHTML={{ __html: getLabel(localization, path) }} />
      ) : (
        <p>
          Before your appointment, it is helpful for us to learn more about your medical and family
          history. <br />
          <br />
          This information will help us to provide the best care for you. <br />
          <br />
          Please try to complete as many of the questions as possible. The more details you can
          provide, the more accurate we can be in our assessment.
          <br />
          It is important to include family members that are alive and deceased.
        </p>
      )}
    </div>
  )
}

export default IntroductionStep
