import { NumericInput } from '@blueprintjs/core'
import _ from 'lodash'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { isOnlyDigits } from '../utils/helpers'

// Need to use `forwardRef` not to affect the other parent components.
const IntegerInput = forwardRef(
  ({ value, min, max, minorStepSize, disabled, onValueChange, ...props }, ref) => {
    const inputProps = {
      ...(min != null && { min }),
      ...(max != null && { max }),
      ...(minorStepSize != null && { minorStepSize }),
      ...(disabled != null && { disabled }),
      ...(props != null && props),
    }

    // The height and weight should not allow "0" value but age should allow "0"
    const isMeasurement = props.className === 'measurementInput'
    const [inputValue, setInputValue] = useState(isMeasurement ? value || '' : value ?? '')

    const isValidInput = (val) => {
      const isValidNumber = isOnlyDigits(val)

      let isValidMinNumber = true
      let isValidMaxNumber = true

      if (isValidNumber) {
        const enteredNumber = parseInt(val)
        isValidMinNumber = min != null ? enteredNumber >= min : true
        isValidMaxNumber = max != null ? enteredNumber <= max : true
      }

      return (isValidNumber && isValidMaxNumber && isValidMinNumber) || _.isEmpty(val)
    }

    const handleValueChange = (_v, val) => {
      if (isValidInput(val)) {
        setInputValue(val)
        if (onValueChange != null) {
          onValueChange(val)
        }
      } else {
        setInputValue('')
      }
    }

    useImperativeHandle(ref, () => ({
      deleteInput() {
        setInputValue('')
      },
    }))

    return <NumericInput {...inputProps} value={inputValue} onValueChange={handleValueChange} />
  },
)

export default IntegerInput
