import { FormGroup, Switch } from '@blueprintjs/core'
import React, { useContext } from 'react'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager'
import IntegerInput from '../widgets/IntegerInput'

const { HEIGHT, WEIGHT } = PersonFieldPaths

const FOOT_TO_INCHES = 12
const FOOT_TO_CM = 30.48

const KILOGRAM_TO_POUNDS = 2.205

const genericInputProps = {
  allowNumericCharactersOnly: true,
  buttonPosition: 'none',
  fill: false,
  large: false,
  selectAllOnFocus: true,
  className: 'measurementInput',
}

const convertHeightFromMetricToImperial = (value) => {
  let result = value / FOOT_TO_CM
  if (result % 1 >= 0.9583) {
    result = Math.round(result)
  }
  const ft = Math.trunc(result)
  const inches = Math.round((result - ft) * FOOT_TO_INCHES)

  return { ft, in: inches }
}

const convertHeightFromImperialToMetric = (foot, inches) => {
  const _foot = Number.isNaN(foot) ? 0 : foot
  const _inches = Number.isNaN(inches) ? 0 : inches

  return _foot * FOOT_TO_CM + (_inches / FOOT_TO_INCHES) * FOOT_TO_CM
}

const convertWeightFromMetricToImperial = (value) => {
  return value * KILOGRAM_TO_POUNDS
}

const convertWeightFromImperialToMetric = (value) => {
  return value / KILOGRAM_TO_POUNDS
}

const getPersonHeightValueFromMetric = (height) => {
  return {
    cm: height,
    ...convertHeightFromMetricToImperial(height),
  }
}

const getPersonHeightValueFromImperial = (foot, inches) => {
  return {
    cm: convertHeightFromImperialToMetric(foot, inches),
    ft: foot,
    in: inches,
  }
}

const getPersonWeightValueFromMetric = (weight) => {
  return {
    kg: weight,
    lb: convertWeightFromMetricToImperial(weight),
  }
}

const getPersonWeightValueFromImperial = (weight) => {
  return {
    kg: convertWeightFromImperialToMetric(weight),
    lb: weight,
  }
}

function MeasurementsSection({
  stateManager,
  isMetric,
  personHeight,
  personWeight,
  setMetric,
  setPersonHeight,
  setPersonWeight,
}) {
  const { localization } = useContext(Context)
  const heightLabel = getLabel(localization, HEIGHT, 'patient')
  const weightLabel = getLabel(localization, WEIGHT, 'patient')

  stateManager.isMetric = isMetric

  const onChangeMetricValue = (val) => {
    stateManager.isMetric = val
    setMetric(val)
  }

  const onChangeMetricHeightValue = (val) => {
    const newHeight = getPersonHeightValueFromMetric(parseFloat(val))
    setPersonHeight(newHeight.cm === 0 || Number.isNaN(newHeight.cm) ? undefined : newHeight.cm)
  }

  const onChangeImperialHeightValue = (foot, inches) => {
    const newHeight = getPersonHeightValueFromImperial(parseFloat(foot), parseFloat(inches))
    setPersonHeight(newHeight.cm === 0 ? undefined : newHeight.cm)
  }

  const onChangeMetricWeightValue = (val) => {
    const newWeight = getPersonWeightValueFromMetric(parseFloat(val))
    setPersonWeight(newWeight.kg === 0 || Number.isNaN(newWeight.kg) ? undefined : newWeight.kg)
  }

  const onChangeImperialWeightValue = (val) => {
    const newWeight = getPersonWeightValueFromImperial(parseFloat(val))
    setPersonWeight(newWeight.kg === 0 || Number.isNaN(newWeight.kg) ? undefined : newWeight.kg)
  }

  return (
    <div className="measurements">
      <div className="measurements-header">
        <label className="bp3-label title">Measurements</label>
        <Switch
          checked={stateManager.isMetric}
          label="metric"
          onChange={() => onChangeMetricValue(!stateManager.isMetric)}
        />
      </div>
      {stateManager.isMetric ? (
        <FormGroup label={heightLabel} className="measurement-group">
          <IntegerInput
            {...genericInputProps}
            onValueChange={(val) => onChangeMetricHeightValue(val)}
            value={Math.round(getPersonHeightValueFromMetric(personHeight).cm)}
            min={0}
            key="cm"
          />
          <span className="bp3-label">cm</span>
        </FormGroup>
      ) : (
        <FormGroup label={heightLabel} className="measurement-group">
          <IntegerInput
            {...genericInputProps}
            onValueChange={(val) =>
              onChangeImperialHeightValue(val, getPersonHeightValueFromMetric(personHeight).in)
            }
            value={Math.round(getPersonHeightValueFromMetric(personHeight).ft)}
            min={0}
            key="ft"
          />
          <span className="bp3-label">feet</span>
          <IntegerInput
            {...genericInputProps}
            onValueChange={(val) =>
              onChangeImperialHeightValue(getPersonHeightValueFromMetric(personHeight).ft, val)
            }
            value={Math.round(getPersonHeightValueFromMetric(personHeight).in)}
            min={0}
            key="in"
          />
          <span className="bp3-label">inches</span>
        </FormGroup>
      )}
      {stateManager.isMetric ? (
        <FormGroup label={weightLabel} className="measurement-group">
          <IntegerInput
            {...genericInputProps}
            onValueChange={(val) => onChangeMetricWeightValue(val)}
            value={Math.round(getPersonWeightValueFromMetric(personWeight).kg)}
            min={0}
            key="kg"
          />
          <span className="bp3-label">Kg</span>
        </FormGroup>
      ) : (
        <FormGroup label={weightLabel} className="measurement-group">
          <IntegerInput
            {...genericInputProps}
            onValueChange={(val) => onChangeImperialWeightValue(val)}
            value={Math.round(getPersonWeightValueFromMetric(personWeight).lb)}
            min={0}
            key="lbs"
          />
          <span className="bp3-label">lbs</span>
        </FormGroup>
      )}
    </div>
  )
}

export default MeasurementsSection
