import { PersonFieldPaths, PersonConditions } from './QuestionnaireStateManager'

export const conditionRelativeQuestions = [
  {
    id: PersonConditions.PANCREATITIS,
    title: 'Chronic Pancreatitis',
    propertyPath: PersonFieldPaths.PANCREATITIS_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.PANCREATITIS,
  },
  {
    id: PersonConditions.TUMOUR,
    title: 'Tumours & Growths',
    propertyPath: PersonFieldPaths.TUMOUR_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.TUMOUR,
  },
  {
    id: PersonConditions.MOLE_NEVI,
    title: 'Moles & Nevis',
    propertyPath: PersonFieldPaths.MOLE_NEVI_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.MOLE_NEVI,
  },
  {
    id: PersonConditions.POLYPS_REMOVED,
    title: 'Polyps Removed',
    propertyPath: PersonFieldPaths.POLYPS_REMOVED_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.POLYPS_REMOVED,
  },
]

export default conditionRelativeQuestions
