import { Callout, Checkbox, FormGroup, InputGroup, Switch, TextArea } from '@blueprintjs/core'
import _ from 'lodash'
import React, { Component } from 'react'
import { PERSON_CONDITIONS } from '../utils/ConditionTypes'
import Context from '../utils/context/Context'
import { getLabel, getSiblingStr } from '../utils/helpers'
import {
  getFieldName,
  PersonConditions,
  PersonDiagnosticTests,
  PersonFieldPaths as paths,
  PersonProperties,
} from '../utils/QuestionnaireStateManager.js'
import CancerHistory from '../widgets/CancerHistory'
import Chooser from '../widgets/Chooser'
import ConditionalSection from '../widgets/ConditionalSection'
import DOBComponent from '../widgets/DOBComponent'
import DropdownSelector from '../widgets/DropdownSelector'
import FullName from '../widgets/FullName'
import IntegerInput from '../widgets/IntegerInput'
import LifeStatus from '../widgets/LifeStatus'
import MeasurementsSection from '../widgets/MeasurementsSection'

class PersonStep extends Component {
  static contextType = Context
  state
  constructor(props) {
    super(props)

    this.resolvePersonIdWithCreate = _.partial(this.props.resolvePersonId, true)
    const geneTests = props.stateManager.getPersonValue(this.getPersonId(), paths.GENE_TESTS)
    const sexValue = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)
    const identityValue = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.GENDER_IDENTITY,
    )

    let genderIdentity = null

    if (!_.isNil(identityValue)) {
      genderIdentity = 'Y'
    } else if (['M', 'F'].includes(sexValue)) {
      genderIdentity = 'N'
    }

    this.state = {
      isPositiveForGenes: _.isArray(geneTests) && geneTests.length > 0 ? 'Y' : null,
      hrtTypes: [],
      genderIdentity,
    }
  }

  render() {
    const { sex, type, isHalfSibling, targetPerson } = this.props
    const targetPersonArray = this.props.siblingOfLabel
      ? ['proband', this.props.siblingOfLabel.toLowerCase(), getSiblingStr(this.props.sex)]
      : ['proband']
    const probandSex = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)
    const isCancerQuestionnaire = this.props.stateManager.isCancerQuestionnaireType()

    return (
      <React.Fragment>
        {this.renderFullName(sex, type)}
        {this.renderDOBInput()}
        {!sex && this.renderSexChooser(type)}
        {type === 'proband' && isCancerQuestionnaire && this.renderPatientMeasurements()}
        {type === 'proband' && this.renderDeclareTwins()}
        {type === 'sibling' &&
          !isHalfSibling &&
          this.doesProbandHaveATwin() &&
          this.renderLinkTwins()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderFirstMenstrualPeriod()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderContraceptivePills()}
        {type === 'parentSibling' && this.renderChildrenCount(targetPersonArray, type)}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderMenopauseStatus()}
        {type === 'proband' && isCancerQuestionnaire && probandSex === 'F' && this.renderHRTUsed()}
        {type === 'proband' && isCancerQuestionnaire && this.renderTobaccoUsed()}
        {type === 'proband' && isCancerQuestionnaire && this.renderAlcoholUsed()}
        {type === 'proband' && isCancerQuestionnaire && this.renderCancerProblems()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          this.checkIfHasCancer('HP:0012189') &&
          this.renderMantleRadiationTreatment()}
        {type === 'proband' && isCancerQuestionnaire && this.renderFITStatus()}
        {type === 'proband' && isCancerQuestionnaire && this.renderColonoscopyStatus()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderMammogramStatus()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderBreastBiopsyStatus()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'M' &&
          this.renderProstateBiopsyStatus()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'M' &&
          this.renderElevatedPSAStatus()}
        {type === 'proband' &&
          isCancerQuestionnaire &&
          probandSex === 'F' &&
          this.renderFemaleSurgeries()}
        {type !== 'proband' && this.renderLifeStatus()}
        {type === 'proband' && isCancerQuestionnaire && this.renderConditions(probandSex === 'F')}
        {type === 'proband' && this.renderGeneticTestingInfo()}
        {type === 'proband' && isCancerQuestionnaire && this.renderGenesTestStatus()}
        {(type === 'proband' || targetPerson === 'mother' || targetPerson === 'father') &&
          this.renderAdoptionInfo(targetPerson, type)}
      </React.Fragment>
    )
  }

  renderDeclareTwins() {
    return (
      <Chooser
        label={getLabel(this.context.localization, paths.IS_TWIN, 'patient')}
        subLabel={
          <span className="subLabel">
            (If yes, please choose &ldquo;sex assigned at birth&rdquo; above)
          </span>
        }
        onChange={async (e) => {
          if (e === 'U' || e === 'N') {
            await this.removeRelativeTwin(e)
          }

          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.PROPERTY_IS_PRESENT,
            e,
            { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN } },
          )
        }}
        selectedValue={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.PROPERTY_IS_PRESENT,
          { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN } },
        )}
        orderSchema={['Y', 'N', 'U']}
        path={'yesNoUnknown'}
        person={'patient'}
        disabled={[this.props.stateManager.getProband().sex === 'U']}
      />
    )
  }

  renderLinkTwins = () => {
    return (
      <Chooser
        label={getLabel(this.context.localization, paths.IS_TWIN, 'person')}
        onChange={(e) => {
          this.props.stateManager.setTwinRelationship(
            this.resolvePersonIdWithCreate,
            this.props.stateManager.getProband().id,
            e,
          )

          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.PROPERTY_VALUE,
            e,
            { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN } },
          )
        }}
        selectedValue={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.PROPERTY_VALUE,
          { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN } },
        )}
        orderSchema={['monozygoticTwin', 'dizygoticTwin', 'N']}
        path={paths.IS_TWIN}
      />
    )
  }

  renderFullName(sex, type) {
    return (
      <FullName
        id={this.getPersonId()}
        getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
        setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
        personIdWithCreate={this.resolvePersonIdWithCreate}
        sex={sex}
        type={type}
      />
    )
  }

  renderChildrenCount(targetPersonArray, type) {
    return (
      <ConditionalSection
        label={
          type === 'proband'
            ? getLabel(this.context.localization, paths.HAS_CHILDREN, 'patient')
            : getLabel(this.context.localization, paths.HAS_CHILDREN, 'person')
        }
        showWhenCondition="Y"
        conditionState={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.PROPERTY_IS_PRESENT,
          { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.HAS_CHILDREN } },
        )}
        onConditionStateChange={(e) => {
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.PROPERTY_IS_PRESENT,
            e,
            { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: PersonProperties.HAS_CHILDREN } },
          )
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => {
          ;['M', 'F'].forEach((childSex) =>
            this.props.stateManager.setChildCount(
              this.resolvePersonIdWithCreate,
              childSex,
              0,
              targetPersonArray,
            ),
          )
        }}
      >
        {this.renderChildrenCountItem('M', targetPersonArray)}
        {this.renderChildrenCountItem('F', targetPersonArray)}
      </ConditionalSection>
    )
  }

  renderChildrenCountItem(childSex, targetPersonArray) {
    const sexLabel = { M: 'boys', F: 'girls' }[childSex]
    const changeHandler = (val) =>
      this.props.stateManager.setChildCount(
        this.resolvePersonIdWithCreate,
        childSex,
        val,
        targetPersonArray,
      )

    const children = this.props.stateManager
      .getFamilyHelper()
      .getChildren(this.getPersonId(), childSex)

    return (
      <FormGroup label={'How many ' + sexLabel + '?'}>
        <IntegerInput
          onValueChange={changeHandler}
          value={children.length}
          minorStepSize={null}
          min={0}
          max={30}
        />
      </FormGroup>
    )
  }

  renderPatientMeasurements() {
    const updateMetricSystem = (val) => {
      this.props.stateManager.modifyState((draftState) => {
        draftState.isMetricMeasurementSystem = val
      })
    }

    return (
      <MeasurementsSection
        stateManager={this.props.stateManager}
        isMetric={this.props.stateManager.cpt.state.isMetricMeasurementSystem}
        personHeight={+this.props.stateManager.getPersonValue(this.getPersonId(), paths.HEIGHT)}
        personWeight={+this.props.stateManager.getPersonValue(this.getPersonId(), paths.WEIGHT)}
        setMetric={updateMetricSystem}
        setPersonHeight={(val) =>
          this.props.stateManager.setPersonValue(this.getPersonId(), paths.HEIGHT, val)
        }
        setPersonWeight={(val) =>
          this.props.stateManager.setPersonValue(this.getPersonId(), paths.WEIGHT, val)
        }
      />
    )
  }

  /**
   * Remove relatives's `twin` attribute in `properties`.
   * @param {String} newSex A gender clicked by the user.
   */
  async removeRelativeTwin() {
    const { persons } = this.props.stateManager.getState()

    // Because `Triplet` can be available.
    const updatePersonValues = []

    for (const personId in persons) {
      if (persons[personId].relationshipToProband !== 'proband') {
        const twinIndex = persons[personId].properties.findIndex(
          (property) => property.type === 'twin',
        )

        if (twinIndex > -1) {
          await updatePersonValues.push(
            this.props.stateManager.updatePersonValue({
              personId,
              path: [paths.PROPERTIES],
              targetIndex: twinIndex,
            }),
          )
        }
      }
    }

    await Promise.all(updatePersonValues)
  }

  renderSexChooser(type) {
    const { sex } = this.props.stateManager.getProband()

    if (type === 'proband') {
      return (
        <React.Fragment>
          <Chooser
            label="Sex assigned at birth *"
            onChange={async (e) => {
              // Need to block the function when the user clicks the same sex in the buttons.
              if (sex !== e) {
                await this.removeRelativeTwin(e)
              }
              if (e !== 'F') {
                this.props.stateManager.setPersonValue(
                  this.resolvePersonIdWithCreate,
                  paths.LAST_NAME_AT_BIRTH,
                  null,
                )
              }
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.SEX, e)
              this.setState({ genderIdentity: null })
              this.clearSexSpecificQuestions()
            }}
            selectedValue={this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)}
            orderSchema={['M', 'F']}
            path={'sex'}
          />
          {this.renderMaidenNameOfFemale()}
          <ConditionalSection
            label={'Is your gender identity different than this?'}
            showWhenCondition="Y"
            conditionState={this.state.genderIdentity}
            onConditionStateChange={(e) => {
              this.setState({ genderIdentity: e })
            }}
            orderSchema={['Y', 'N']}
            cleanUpChildQuestions={() => {
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.GENDER_IDENTITY,
                null,
              )
            }}
          >
            <Chooser
              label="What is your current identity?"
              onChange={(e) =>
                this.props.stateManager.setPersonValue(this.getPersonId(), paths.GENDER_IDENTITY, e)
              }
              selectedValue={this.props.stateManager.getPersonValue(
                this.getPersonId(),
                paths.GENDER_IDENTITY,
              )}
              orderSchema={['M', 'F', 'nonBinary', 'O'].filter((item) => sex !== item)}
              patient={'patient'}
              path={'genderIdentity'}
            />
          </ConditionalSection>
        </React.Fragment>
      )
    } else {
      return (
        <Chooser
          selectedValue={this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)}
          onChange={(e) =>
            this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.SEX, e)
          }
          orderSchema={['M', 'F', 'U', 'O']}
          path={'sex'}
        />
      )
    }
  }

  renderMaidenNameOfFemale() {
    const gender = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)
    const { name: { lastNameAtBirth = '' } = {} } =
      this.props.stateManager.getState().persons[this.getPersonId()]
    if (gender === 'F') {
      return (
        <FormGroup label={getLabel(this.context.localization, paths.LAST_NAME_AT_BIRTH)}>
          <InputGroup
            type="text"
            onChange={(e) =>
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.LAST_NAME_AT_BIRTH,
                e.target.value || '',
              )
            }
            value={lastNameAtBirth}
          />
        </FormGroup>
      )
    }
  }

  clearSexSpecificQuestions() {
    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.GENDER_IDENTITY,
      null,
    )
    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.LAST_NAME_AT_BIRTH,
      null,
    )

    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.FIRST_MENSTRUAL_PERIOD,
      null,
    )
    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.ORAL_CONTRACEPTIVE_USAGE,
      null,
    )
    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.MENOPAUSE_STATUS,
      null,
    )
    this.props.stateManager.setPersonValue(
      this.resolvePersonIdWithCreate,
      paths.AGE_AT_MENOPAUSE,
      null,
    )
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_USAGE, null)
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_TYPE, null)
    this.removeCondition(PersonConditions.ENDOMETRIOSIS_TYPE)
    this.props.stateManager.setPersonValue(this.getPersonId(), paths.SURGERIES, [])
    this.props.stateManager.setPersonValue(this.getPersonId(), paths.DIAGNOSTIC_TESTS, [])
  }

  renderDOBInput() {
    return (
      <DOBComponent
        stateManager={this.props.stateManager}
        dobDateStr={this.props.stateManager.getPersonValue(this.getPersonId(), paths.DATE_OF_BIRTH)}
        onChange={(date) => {
          // eslint-disable-next-line
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.DATE_OF_BIRTH,
            date && date !== 'Invalid Date' ? date.toISOString() : '',
          )
        }}
      />
    )
  }

  renderLifeStatus() {
    return (
      <LifeStatus
        personId={this.getPersonId()}
        personIdWithCreate={this.resolvePersonIdWithCreate}
        stateManager={this.props.stateManager}
      />
    )
  }

  renderCancerProblems() {
    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.DIAGNOSED_WITH_CANCER, 'patient')}
        showWhenCondition="Y"
        conditionState={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.PROPERTY_IS_PRESENT,
          { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'hasCancer' } },
        )}
        onConditionStateChange={(e) => {
          this.props.stateManager.setPersonValue(this.getPersonId(), paths.PROPERTY_IS_PRESENT, e, {
            propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'hasCancer' },
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => {
          this.props.stateManager.setPersonValue(this.getPersonId(), paths.CANCERS, [])
        }}
      >
        <CancerHistory
          label={getLabel(this.context.localization, paths.TYPES_OF_CANCER, 'person')}
          personId={this.getPersonId()}
          stateManager={this.props.stateManager}
        ></CancerHistory>
      </ConditionalSection>
    )
  }

  renderMantleRadiationTreatment() {
    return (
      <Chooser
        label={getLabel(this.context.localization, paths.TREATMENT_MANTLE_RADIATION, 'person')}
        selectedValue={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.TREATMENT_IS_PERFORMED,
          { treatmentIdx: { [getFieldName(paths.TREATMENT_TYPE)]: 'mantleRadiation' } },
        )}
        onChange={(e) =>
          this.props.stateManager.setPersonValue(
            this.getPersonId(),
            paths.TREATMENT_IS_PERFORMED,
            e,
            { treatmentIdx: { [getFieldName(paths.TREATMENT_TYPE)]: 'mantleRadiation' } },
          )
        }
        orderSchema={['Y', 'N', 'U']}
        path={'yesNoUnknown'}
      />
    )
  }

  removeCondition(conditionId) {
    const list = _.filter(
      this.props.stateManager.getPersonValue(this.getPersonId(), paths.CONDITIONS),
      (item) => item.id !== conditionId,
    )

    this.props.stateManager.setPersonValue(this.getPersonId(), paths.CONDITIONS, list)
  }

  setCondition = (conditionId, labelPath) => {
    const label =
      conditionId === PersonConditions.LCIS
        ? PERSON_CONDITIONS.find((c) => c.id === conditionId)?.label
        : getLabel(this.context.localization, labelPath, paths.CONDITIONS)

    this.props.stateManager.addToPersonArray(this.getPersonId(), paths.CONDITIONS, {
      id: PERSON_CONDITIONS.find((c) => c.id === conditionId)?.id,
      type: PERSON_CONDITIONS.find((c) => c.id === conditionId)?.type,
      label,
      isPresent: 'Y',
    })
  }

  getConditionValue = (conditionId) => {
    return (
      this.props.stateManager.getPersonValue(this.getPersonId(), paths.CONDITION_IS_PRESENT, {
        propertyIdx: { id: conditionId },
      }) || 'N'
    )
  }

  renderConditions(isProbandFemale) {
    const ConditionSwitch = ({ conditionId, labelPath }) => (
      <Switch
        checked={this.getConditionValue(conditionId) === 'Y'}
        label={getLabel(this.context.localization, labelPath, paths.CONDITIONS)}
        onChange={(e) => {
          e.currentTarget.checked
            ? this.setCondition(conditionId, labelPath)
            : this.removeCondition(conditionId)
        }}
        innerLabelChecked="Yes"
        innerLabel="No"
      />
    )

    return (
      <div className="bp3-form-group">
        {/* Requires a localization for proband conditions */}
        <label className="bp3-label">Have you had any of the following conditions?</label>
        <ConditionSwitch
          conditionId={PersonConditions.PANCREATITIS}
          labelPath={paths.PANCREATITIS}
        />
        <ConditionSwitch conditionId={PersonConditions.TUMOUR} labelPath={paths.TUMOUR} />
        <ConditionSwitch conditionId={PersonConditions.MOLE_NEVI} labelPath={paths.MOLE_NEVI} />
        <ConditionSwitch
          conditionId={PersonConditions.POLYPS_REMOVED}
          labelPath={paths.POLYPS_REMOVED}
        />
        {isProbandFemale && (
          <ConditionSwitch
            conditionId={PersonConditions.ENDOMETRIOSIS}
            labelPath={paths.ENDOMETRIOSIS}
          />
        )}
      </div>
    )
  }

  renderGeneticTestingInfo() {
    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient')}
        showWhenCondition="Y"
        conditionState={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.GENETIC_TESTING_PERFORMED,
        )}
        onConditionStateChange={(e) =>
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.GENETIC_TESTING_PERFORMED,
            e,
          )
        }
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() =>
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.GENETIC_TESTING_DESCRIPTION,
            null,
          )
        }
      >
        <FormGroup
          label={getLabel(this.context.localization, paths.GENETIC_TESTING_DESCRIPTION, 'patient')}
        >
          <TextArea
            rows={1}
            growVertically={true}
            small={false}
            maxLength="65000"
            onChange={(e) => {
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.GENETIC_TESTING_DESCRIPTION,
                e.target.value,
              )
            }}
            value={
              this.props.stateManager.getPersonValue(
                this.getPersonId(),
                paths.GENETIC_TESTING_DESCRIPTION,
              ) || ''
            }
          />
        </FormGroup>
      </ConditionalSection>
    )
  }

  renderFirstMenstrualPeriod() {
    return (
      <FormGroup label={getLabel(this.context.localization, paths.AGE_AT_MENARCHE, 'patient')}>
        <IntegerInput
          onValueChange={(val) =>
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.AGE_AT_MENARCHE,
              val,
            )
          }
          value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.AGE_AT_MENARCHE)}
          min={0}
          max={150}
        />
      </FormGroup>
    )
  }

  renderContraceptivePills() {
    return (
      <Chooser
        label={getLabel(this.context.localization, paths.ORAL_CONTRACEPTIVE_PILLS_USAGE, 'patient')}
        onChange={(e) =>
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.ORAL_CONTRACEPTIVE_USAGE,
            e,
          )
        }
        selectedValue={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.ORAL_CONTRACEPTIVE_USAGE,
        )}
        orderSchema={['never', 'unknown', 'stopped2', 'usedWithin2']}
        patient={'patient'}
        path={paths.ORAL_CONTRACEPTIVE_PILLS_USAGE}
      />
    )
  }

  renderMenopauseStatus() {
    return (
      <div>
        <Chooser
          label={getLabel(this.context.localization, paths.MENOPAUSE_STATUS, 'patient')}
          onChange={(e) => {
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.MENOPAUSE_STATUS,
              e,
            )

            if (e !== 'Y' || e !== 'C') {
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.AGE_AT_MENOPAUSE,
                null,
              )
            }
          }}
          selectedValue={this.props.stateManager.getPersonValue(
            this.getPersonId(),
            paths.MENOPAUSE_STATUS,
          )}
          orderSchema={['Y', 'inMenopause', 'N', 'unknown']}
          patient={'patient'}
          path={paths.MENOPAUSE_STATUS}
        />
        {this.renderAgeAtMenopause()}
      </div>
    )
  }

  renderAgeAtMenopause() {
    const menopauseStatus = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.MENOPAUSE_STATUS,
    )

    if (menopauseStatus === 'Y' || menopauseStatus === 'C') {
      return (
        <FormGroup
          label={getLabel(this.context.localization, paths.AGE_AT_MENOPAUSE_LABEL, 'patient')}
        >
          <IntegerInput
            onValueChange={(val) =>
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.AGE_AT_MENOPAUSE,
                val,
              )
            }
            value={this.props.stateManager.getPersonValue(
              this.getPersonId(),
              paths.AGE_AT_MENOPAUSE,
            )}
            min={0}
            max={150}
          />
        </FormGroup>
      )
    }

    return null
  }

  renderHRTUsed() {
    return (
      <div>
        <Chooser
          label={getLabel(this.context.localization, paths.HRT_USAGE, 'patient')}
          onChange={(e) => {
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.HRT_USAGE,
              e,
            )

            if (e !== 'using' || e !== 'stoppedWithin5' || e !== 'stoppedMore5') {
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.HRT_TYPE,
                null,
              )
            }
          }}
          selectedValue={this.props.stateManager.getPersonValue(
            this.getPersonId(),
            paths.HRT_USAGE,
          )}
          orderSchema={['using', 'stoppedWithin5', 'stoppedMore5', 'N', 'unknown']}
          patient={'patient'}
          path={paths.HRT_USAGE}
        />
        {this.renderHRTTypes()}
      </div>
    )
  }

  renderHRTTypes() {
    const hrtUsage = this.props.stateManager.getPersonValue(this.getPersonId(), paths.HRT_USAGE)
    if (hrtUsage === 'using' || hrtUsage === 'stoppedWithin5' || hrtUsage === 'stoppedMore5') {
      return (
        <Chooser
          label={getLabel(this.context.localization, paths.HRT_TYPE_LABEL, 'patient')}
          onChange={(e) => {
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.HRT_TYPE,
              e,
            )
          }}
          selectedValue={this.props.stateManager.getPersonValue(this.getPersonId(), paths.HRT_TYPE)}
          orderSchema={['estrogen', 'combined', 'unknown']}
          patient={'patient'}
          path={paths.HRT_TYPE_LABEL}
        />
      )
    }

    return null
  }

  renderTobaccoUsed() {
    return (
      <div>
        <Chooser
          label={getLabel(this.context.localization, paths.TOBACCO_USAGE, 'patient')}
          onChange={(e) => {
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.TOBACCO_USAGE,
              e,
            )

            if (e !== 'currently' || e !== 'previously') {
              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.AVERAGE_CIGARETTES_DAY,
                null,
              )

              this.props.stateManager.setPersonValue(
                this.resolvePersonIdWithCreate,
                paths.TOBACCO_TOTAL_YEARS,
                null,
              )
            }
          }}
          selectedValue={this.props.stateManager.getPersonValue(
            this.getPersonId(),
            paths.TOBACCO_USAGE,
          )}
          orderSchema={['currently', 'previously', 'no']}
          patient={'patient'}
          path={paths.TOBACCO_USAGE}
        />
        {this.renderTobaccoQuestions()}
      </div>
    )
  }

  renderTobaccoQuestions() {
    const tobaccoUsage = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.TOBACCO_USAGE,
    )

    if (tobaccoUsage === 'currently' || tobaccoUsage === 'previously') {
      return (
        <>
          <FormGroup
            label={getLabel(
              this.context.localization,
              paths.AVERAGE_CIGARETTES_DAY_LABEL,
              'patient',
            )}
          >
            <IntegerInput
              onValueChange={(val) =>
                this.props.stateManager.setPersonValue(
                  this.resolvePersonIdWithCreate,
                  paths.AVERAGE_CIGARETTES_DAY,
                  val,
                )
              }
              value={this.props.stateManager.getPersonValue(
                this.getPersonId(),
                paths.AVERAGE_CIGARETTES_DAY,
              )}
              min={1}
              max={150}
            />
          </FormGroup>
          <FormGroup
            label={getLabel(this.context.localization, paths.TOBACCO_TOTAL_YEARS_LABEL, 'patient')}
          >
            <IntegerInput
              onValueChange={(val) =>
                this.props.stateManager.setPersonValue(
                  this.resolvePersonIdWithCreate,
                  paths.TOBACCO_TOTAL_YEARS,
                  val,
                )
              }
              value={this.props.stateManager.getPersonValue(
                this.getPersonId(),
                paths.TOBACCO_TOTAL_YEARS,
              )}
              min={0}
              max={150}
            />
          </FormGroup>
        </>
      )
    }

    return null
  }

  renderAlcoholUsed() {
    const personId = this.getPersonId()
    const alcohol = this.props.stateManager.getPersonValue(personId, paths.ALCOHOL_USAGE)

    const typeOfDrinks = [
      { label: 'Glass of Wine (175 ml / 11% ABV)', id: 'wine' },
      { label: 'Pint of Beer (568 ml / 4% ABV)', id: 'beerPint' },
      { label: 'Bottle of Beer (330 ml / 5% ABV)', id: 'beerBottle' },
      { label: 'Alcoholic Pop Drink (275 ml / 4% ABV)', id: 'alcoholicPop' },
      { label: 'Shot of Spirits (25 ml / 40% ABV)', id: 'spiritShot' },
    ]

    const { cancerRiskData = {} } = this.props.stateManager.getProband()
    const alcoholConsumptionDetails = cancerRiskData.alcoholConsumptionDetails || []
    const { typeOfDrink = '' } = alcoholConsumptionDetails[0] || {}

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.ALCOHOL_USAGE_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={!_.isNil(alcohol) ? (alcohol ? 'Y' : 'N') : null}
        onConditionStateChange={async (e) => {
          if (e === 'Y') {
            await this.props.stateManager.updatePersonValue({
              personId,
              path: ['cancerRiskData'],
              field: 'consumesAlcohol',
              newFieldValue: true,
            })
          }
        }}
        orderSchema={['Y', 'N']}
        cleanUpChildQuestions={async () => {
          const updatePersonFields = [
            await this.props.stateManager.updatePersonValue({
              personId,
              path: ['cancerRiskData'],
              field: 'consumesAlcohol',
              newFieldValue: false,
            }),
            await this.props.stateManager.updatePersonValue({
              personId,
              path: ['cancerRiskData'],
              field: 'alcoholConsumptionDetails',
              newFieldValue: [{}],
            }),
          ]

          await Promise.all(updatePersonFields)
        }}
      >
        <FormGroup label="What is your drink type?" className="dropdown">
          <DropdownSelector
            defaultLabel="Choose a drink type"
            items={typeOfDrinks}
            onItemSelect={(selectedItem) => {
              const targetIndex = alcoholConsumptionDetails.findIndex(
                ({ typeOfDrink: drinkType = '' }) => drinkType,
              )

              this.props.stateManager.updatePersonValue({
                personId,
                path: ['cancerRiskData'],
                field: 'alcoholConsumptionDetails',
                // which means the target property has the array.
                targetIndex,
                newFieldValue: {
                  ...alcoholConsumptionDetails[targetIndex],
                  typeOfDrink: selectedItem.id,
                },
              })
            }}
            savedValue={typeOfDrinks.find(({ id }) => typeOfDrink === id)?.label}
          />
        </FormGroup>
        <FormGroup
          label={getLabel(this.context.localization, paths.ALCOHOL_AVG_MONTH_LABEL, 'patient')}
        >
          <IntegerInput
            onValueChange={(val) => {
              const targetIndex = alcoholConsumptionDetails.findIndex(
                ({ typeOfDrink: drinkType = '' }) => drinkType,
              )

              const newFieldValue = {
                ...alcoholConsumptionDetails[targetIndex],
                drinkAmount: val,
                drinkingFrequency: 'weekly',
              }

              // only empty string, not "0"
              if (val === '') {
                delete newFieldValue.drinkAmount
              }

              this.props.stateManager.updatePersonValue({
                personId,
                path: ['cancerRiskData'],
                field: 'alcoholConsumptionDetails',
                // which means the target property has the array.
                targetIndex,
                newFieldValue,
              })
            }}
            value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.DRINK_AMOUNT)}
            min={1}
            max={150}
            disabled={!typeOfDrink}
          />
        </FormGroup>
      </ConditionalSection>
    )
  }

  renderFITStatus() {
    const fitDiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.FIT)

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.FIT_STATUS_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={fitDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.FIT)
          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.DIAGNOSTIC_TESTS,
            index,
          )
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: PersonDiagnosticTests.FIT,
            isPerformed: e,
            result: null,
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        <Chooser
          label={getLabel(this.context.localization, paths.DIAGNOSTIC_RESULT_LABEL, 'patient')}
          onChange={(e) => {
            const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.FIT)

            this.props.stateManager.removeFromArrayInPath(
              this.getPersonId(),
              paths.DIAGNOSTIC_TESTS,
              index,
            )

            this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
              type: PersonDiagnosticTests.FIT,
              isPerformed: 'Y',
              result: e,
            })
          }}
          selectedValue={fitDiagnostic.result}
          orderSchema={['normal', 'abnormal', 'noResult']}
          patient={'patient'}
          path={paths.DIAGNOSTIC_RESULT_LABEL}
        />
      </ConditionalSection>
    )
  }

  getDiagnosticTest(type) {
    const diagnosticTests = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.DIAGNOSTIC_TESTS,
    )

    const requiredDiagnosticTest = _.find(diagnosticTests, { type })

    return requiredDiagnosticTest || {}
  }

  getDiagnosticTestIndex(type) {
    const diagnosticTests = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.DIAGNOSTIC_TESTS,
    )
    const index = _.findIndex(diagnosticTests, { type })

    return index
  }

  renderColonoscopyStatus() {
    const colonoscopyDiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.COLONOSCOPY)

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.COLONOSCOPY_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={colonoscopyDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.COLONOSCOPY)

          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.DIAGNOSTIC_TESTS,
            index,
          )

          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: PersonDiagnosticTests.COLONOSCOPY,
            isPerformed: e,
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        <FormGroup
          label={getLabel(this.context.localization, paths.COLONOSCOPY_AGE_LABEL, 'patient')}
        >
          <IntegerInput
            onValueChange={(value) => {
              const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.COLONOSCOPY)

              this.props.stateManager.removeFromArrayInPath(
                this.getPersonId(),
                paths.DIAGNOSTIC_TESTS,
                index,
              )

              this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
                type: PersonDiagnosticTests.COLONOSCOPY,
                isPerformed: 'Y',
                ageAtTestPerformed: value,
              })

              // only empty string, not "0"
              if (value === '') {
                this.props.stateManager.updatePersonValue({
                  personId: this.getPersonId(),
                  path: ['diagnosticTests'],
                  targetIndex: index,
                  newFieldValue: {
                    type: 'colonoscopy',
                    isPerformed: colonoscopyDiagnostic.isPerformed,
                  },
                })
              }
            }}
            value={colonoscopyDiagnostic.ageAtTestPerformed}
            min={0}
            max={150}
          />
        </FormGroup>
      </ConditionalSection>
    )
  }

  renderMammogramStatus() {
    const mammogramDiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.BREAST_MAMMOGRAM)

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.BREAST_MAMMOGRAM_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={mammogramDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.BREAST_MAMMOGRAM)
          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.DIAGNOSTIC_TESTS,
            index,
          )
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: PersonDiagnosticTests.BREAST_MAMMOGRAM,
            isPerformed: e,
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      ></ConditionalSection>
    )
  }

  renderBreastBiopsyStatus() {
    const isLCIS = () => {
      if (this.getConditionValue(PersonConditions.LCIS) === 'Y') {
        this.removeCondition(PersonConditions.LCIS)
      }
    }

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.BREAST_BIOPSY_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={this.props.stateManager.getPersonValue(
          this.getPersonId(),
          paths.PREVIOUS_BREAST_BIOPSIES,
        )}
        onConditionStateChange={(e) => {
          if (e !== 'Y' && isLCIS()) {
            this.removeCondition(PersonConditions.LCIS)
          }

          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.PREVIOUS_BREAST_BIOPSIES,
            e,
          )
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() =>
          this.props.stateManager.setPersonValue(
            this.resolvePersonIdWithCreate,
            paths.BREAST_BIOPSY_RESULT,
            null,
          )
        }
      >
        <Chooser
          label={getLabel(this.context.localization, paths.BREAST_BIOPSY_RESULT_LABEL, 'patient')}
          onChange={(e) => {
            if (e === 'lcis' && !isLCIS()) {
              this.setCondition(PersonConditions.LCIS, paths.LCIS)
            } else if (isLCIS()) {
              this.removeCondition(PersonConditions.LCIS)
            }

            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.BREAST_BIOPSY_RESULT,
              e,
            )
          }}
          selectedValue={this.props.stateManager.getPersonValue(
            this.getPersonId(),
            paths.BREAST_BIOPSY_RESULT,
          )}
          orderSchema={['normal', 'lcis', 'atypicalHyperplasia', 'hyperplasia', 'unknown']}
          patient={'patient'}
          path={paths.BREAST_BIOPSY_RESULT_OPTIONS}
        />
      </ConditionalSection>
    )
  }

  renderProstateBiopsyStatus() {
    const prostateBiopsyDiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.PROSTATE_BIOPSY)

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.PROSTATE_BIOPSY_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={prostateBiopsyDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.PROSTATE_BIOPSY)

          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.DIAGNOSTIC_TESTS,
            index,
          )

          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: PersonDiagnosticTests.PROSTATE_BIOPSY,
            isPerformed: e,
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      ></ConditionalSection>
    )
  }

  renderElevatedPSAStatus() {
    const elevatedPSADiagnostic = this.getDiagnosticTest(PersonDiagnosticTests.ELEVATED_PSA)

    return (
      <ConditionalSection
        label={getLabel(this.context.localization, paths.ELEVATED_PSA_LABEL, 'patient')}
        showWhenCondition="Y"
        conditionState={elevatedPSADiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex(PersonDiagnosticTests.ELEVATED_PSA)

          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.DIAGNOSTIC_TESTS,
            index,
          )

          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: PersonDiagnosticTests.ELEVATED_PSA,
            isPerformed: e,
          })
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      ></ConditionalSection>
    )
  }

  renderFemaleSurgeries() {
    const surgeries = [
      {
        id: 'lumpectomy',
        name: getLabel(this.context.localization, paths.LUMPECTOMY_LABEL, 'patient'),
        tissue: 'breasts',
      },
      {
        id: 'mastectomy',
        name: getLabel(this.context.localization, paths.MASTECTOMY_LABEL, 'patient'),
        tissue: 'breasts',
      },
      {
        id: 'hysterectomy',
        name: getLabel(this.context.localization, paths.HYSTERECTOMY_LABEL, 'patient'),
        tissue: 'uterus',
      },
      {
        id: 'tubalLigation',
        name: getLabel(this.context.localization, paths.TUBAL_LIGATION_LABEL, 'patient'),
        tissue: 'tubes',
      },
      {
        id: 'oophorectomy',
        name: getLabel(this.context.localization, paths.OOPHORECTOMY_LABEL, 'patient'),
        tissue: 'ovaries',
      },
      {
        id: 'salpingectomy',
        name: getLabel(this.context.localization, paths.SALPINGECTOMY_LABEL, 'patient'),
        tissue: 'fallopian tubes',
      },
    ]

    const storedSurgeries = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.SURGERIES,
    )

    const handleSurgeryChange = (surgery) => () => {
      const surgeryIndex = _.findIndex(storedSurgeries, { type: surgery.id })

      if (surgeryIndex >= 0) {
        this.props.stateManager.removeFromArrayInPath(
          this.getPersonId(),
          paths.SURGERIES,
          surgeryIndex,
        )
      } else {
        this.props.stateManager.addToPersonArray(this.getPersonId(), paths.SURGERIES, {
          type: surgery.id,
        })
      }
    }

    return (
      <FormGroup label={getLabel(this.context.localization, paths.SURGERY_LABEL, 'patient')}>
        {surgeries.map((surgery) => {
          const isChecked = _.includes(_.map(storedSurgeries, 'type'), surgery.id)

          return (
            <div key={surgery.id}>
              <Checkbox
                checked={isChecked}
                large={true}
                label={surgery.name}
                onChange={handleSurgeryChange(surgery)}
              />
              {this.renderSurgerySide(surgery, isChecked, storedSurgeries)}
            </div>
          )
        })}
      </FormGroup>
    )
  }

  renderSurgerySide(surgery, isChecked, storedSurgeries) {
    const allowedSurgeries = ['lumpectomy', 'mastectomy', 'salpingectomy', 'oophorectomy']
    if (isChecked && _.includes(allowedSurgeries, surgery.id)) {
      const selectedValue = _.find(storedSurgeries, { type: surgery.id })

      return (
        <Chooser
          label={`One or both ${surgery.tissue}?`}
          onChange={(e) => {
            const surgeryIndex = _.findIndex(storedSurgeries, { type: surgery.id })

            this.props.stateManager.removeFromArrayInPath(
              this.getPersonId(),
              paths.SURGERIES,
              surgeryIndex,
            )

            this.props.stateManager.addToPersonArray(this.getPersonId(), paths.SURGERIES, {
              type: surgery.id,
              lateralityType: e,
            })
          }}
          selectedValue={selectedValue.lateralityType}
          orderSchema={['left', 'right', 'bothSides']}
          patient={null}
          path={'laterality'}
        />
      )
    }

    return null
  }

  renderGenesTestStatus() {
    const geneticTestingPerformed = this.props.stateManager.getPersonValue(
      this.getPersonId(),
      paths.GENETIC_TESTING_PERFORMED,
    )

    const genes = [
      { id: 'brca1', name: 'BRCA1' },
      { id: 'brca2', name: 'BRCA2' },
      { id: 'palb2', name: 'PALB2' },
      { id: 'chek2', name: 'CHECK2' },
      { id: 'atm', name: 'ATM' },
    ]

    if (geneticTestingPerformed === 'Y') {
      const storedGenes = this.props.stateManager.getPersonValue(
        this.getPersonId(),
        paths.GENE_TESTS,
      )

      const handleGeneChange = (gene) => () => {
        const geneIndex = _.findIndex(storedGenes, { nameOfGeneTest: gene.id })

        if (geneIndex >= 0) {
          this.props.stateManager.removeFromArrayInPath(
            this.getPersonId(),
            paths.GENE_TESTS,
            geneIndex,
          )
        } else {
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.GENE_TESTS, {
            nameOfGeneTest: gene.id,
            geneTestResult: 'positive',
          })
        }
      }

      return (
        <ConditionalSection
          label={getLabel(this.context.localization, paths.POSITIVE_GENES_LABEL, 'patient')}
          showWhenCondition="Y"
          conditionState={this.state.isPositiveForGenes}
          onConditionStateChange={(e) => this.setState({ isPositiveForGenes: e })}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            this.props.stateManager.setPersonValue(this.getPersonId(), paths.GENE_TESTS, [])
          }}
        >
          <FormGroup label={'Which gene(s)?'}>
            {genes.map((gene) => {
              const isChecked = _.includes(_.map(storedGenes, 'nameOfGeneTest'), gene.id)

              return (
                <div key={gene.id}>
                  <Checkbox
                    checked={isChecked}
                    large={true}
                    label={gene.name}
                    onChange={handleGeneChange(gene)}
                  />
                </div>
              )
            })}
          </FormGroup>
        </ConditionalSection>
      )
    }
  }

  renderAdoptionInfo(targetPerson, type) {
    let label = getLabel(this.context.localization, paths.IS_ADOPTED, 'patient')

    if (targetPerson === 'mother') {
      label = getLabel(this.context.localization, paths.IS_MOTHER_ADOPTED, 'patient')
    } else if (targetPerson === 'father') {
      label = getLabel(this.context.localization, paths.IS_FATHER_ADOPTED, 'patient')
    }

    return (
      <>
        <ConditionalSection
          label={label}
          showWhenCondition="Y"
          conditionState={this.props.stateManager.getPersonValue(
            this.getPersonId(),
            paths.IS_ADOPTED,
          )}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(
              this.resolvePersonIdWithCreate,
              paths.IS_ADOPTED,
              e,
            )
          }
          orderSchema={['Y', 'N']}
          cleanUpChildQuestions={() => null}
        >
          {type === 'proband' && (
            <Callout intent="primary">
              <p className="text-size-sm m-0">
                The following questions relate to the health history of your biological&nbsp;
                relatives. You can skip the questions if you do not have this information.
              </p>
            </Callout>
          )}
        </ConditionalSection>
      </>
    )
  }

  getAliveStatus() {
    const val = this.props.stateManager.getPersonValue(this.getPersonId(), paths.ALIVE_STATUS)

    if (val === false) {
      return 'no'
    } else if (val) {
      return 'yes'
    } else {
      return null
    }
  }

  getPersonId() {
    return this.props.resolvePersonId(false, this.props.stateManager.cpt.state)
  }

  doesProbandHaveATwin = () => {
    const hasTwin = this.props.stateManager.getPersonValue(
      this.props.stateManager.getProband().id,
      paths.PROPERTY_IS_PRESENT,
      { propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' } },
    )

    return hasTwin && hasTwin === 'Y'
  }

  checkIfHasCancer = (id) => {
    const cancers = this.props.stateManager.getPersonValue(this.getPersonId(), paths.CANCERS)

    return cancers.filter((cancer) => cancer.id === id).length > 0
  }
}

export default PersonStep
