import { NonIdealState } from '@blueprintjs/core'
import React from 'react'

function PageNotFound() {
  return (
    <div>
      <div className="bp3-non-ideal-state">
        <NonIdealState
          icon="error"
          title="Sorry, we couldn't find that page"
          description={
            'Please go back or log in again, ' +
            'To log in, please paste the link provided to you by your service provider.'
          }
        />
      </div>
    </div>
  )
}

export default PageNotFound
