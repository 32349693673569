import _ from 'lodash'
import React, { Component, createContext } from 'react'
import AdditionalInformation from '../steps/AdditionalInformation.jsx'
import ChildInfoStep from '../steps/ChildInfoStep.jsx'
import ConclusionStep from '../steps/ConclusionStep.jsx'
import CousinInfoStep from '../steps/CousinInfoStep.jsx'
import InstructionsStep from '../steps/InstructionsStep'
import IntroductionStep from '../steps/IntroductionStep'
import ParentsStep from '../steps/ParentsStep.jsx'
import PersonSiblingsStep from '../steps/PersonSiblingsStep.jsx'
import PersonStep from '../steps/PersonStep.jsx'
import RelativeLinkingQuestionsStep from '../steps/RelativeLinkingQuestionsStep.jsx'
import cancerRelativeQuestions from '../utils/cancerRelativeQuestions.js'
import conditionRelativeQuestions from '../utils/conditionRelativeQuestions.js'
import Context from '../utils/context/Context'
import FamilyHelper from '../utils/FamilyHelper.js'
import { loadFromBackend } from '../utils/persistance'
import {
  PersonFieldPaths,
  PersonProperties,
  QuestionnaireStateManager,
} from '../utils/QuestionnaireStateManager.js'
import Navigation from '../widgets/Navigation.jsx'
import SessionTimeout from '../widgets/SessionTimeout'

// questionnaire type as context so we don't need to pass down stateManager to every single step
export const TypeContext = createContext('simple')

class Questionnaire extends Component {
  static contextType = Context

  constructor(props) {
    super(props)
    this.stateManager = new QuestionnaireStateManager(this)
    this.state = QuestionnaireStateManager.getEmptyState()
  }

  render() {
    const { addToast, timeoutModal, isAuthenticated } = this.context

    if (!isAuthenticated) return <div />

    const { getProband } = this.stateManager
    const probandResolver = (shouldCreate, state) => {
      return QuestionnaireStateManager.getProband(state)[PersonFieldPaths.ID]
    }

    return (
      <SessionTimeout timeoutModal={timeoutModal} setTimeoutModal={timeoutModal}>
        <TypeContext.Provider value={this.stateManager.getQuestionnaireType()}>
          <div id="questionnaire" className="clearfix">
            <Navigation
              cptState={this.stateManager.cpt.state}
              setState={this.stateManager.setState}
              stateManager={this.stateManager}
              proband={getProband()}
              addToast={addToast}
            >
              <IntroductionStep key="introduction" navId="introduction" navTitle={'Introduction'} />
              <InstructionsStep key="instructions" navId="instructions" navTitle={'Instructions'} />
              <ParentsStep
                key="parents"
                navId="parents"
                navTitle={'Parents'}
                icon="people"
                person={getProband()}
                stateManager={this.stateManager}
              />

              {this.renderFamilyStep(
                false,
                'Your Information',
                'proband',
                probandResolver,
                { type: 'proband' },
                'person',
              )}
              {this.renderFamilySteps()}
              {this.stateManager.isCancerQuestionnaireType()
                ? this.renderFolder(
                    'cancerRelativeQuestions',
                    'Cancer Related Questions',
                    cancerRelativeQuestions.map((question) => (
                      <RelativeLinkingQuestionsStep
                        key={question.id}
                        navId={question.id}
                        icon="people"
                        navTitle={question.title}
                        proband={probandResolver(false, this.state)}
                        propertyPath={question.propertyPath}
                        propertyType={question.propertyType}
                        personProperty={question.personProperty}
                        stateManager={this.stateManager}
                        otherQuestions={question.otherQuestions}
                      />
                    )),
                  )
                : null}
              {this.stateManager.isCancerQuestionnaireType()
                ? this.renderFolder(
                    'conditionRelativeQuestions',
                    'Medical Conditions',
                    conditionRelativeQuestions.map((question) => (
                      <RelativeLinkingQuestionsStep
                        key={question.id}
                        navId={question.id}
                        icon="people"
                        navTitle={question.title}
                        proband={probandResolver(false, this.state)}
                        propertyPath={question.propertyPath}
                        propertyType={question.propertyType}
                        personProperty={question.personProperty}
                        stateManager={this.stateManager}
                        otherQuestions={question.otherQuestions}
                      />
                    )),
                  )
                : null}
              <RelativeLinkingQuestionsStep
                key="genetic-testing"
                navId={PersonFieldPaths.GENETIC_TESTING}
                icon="people"
                navTitle="Genetic Testing"
                proband={probandResolver(false, this.state)}
                propertyPath={PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE}
                propertyType={PersonFieldPaths.GENETIC_TESTING}
                personProperty={PersonFieldPaths.GENETIC_TESTING_PERFORMED}
                stateManager={this.stateManager}
                otherQuestions
              />
              <RelativeLinkingQuestionsStep
                key="gender-identity"
                navId={PersonFieldPaths.GENDER_IDENTITY}
                icon="people"
                navTitle="Gender Identity"
                proband={probandResolver(false, this.state)}
                propertyPath={PersonFieldPaths.GENDER_IDENTITY}
                propertyType={PersonFieldPaths.PROPERTIES}
                personProperty={PersonProperties.HAS_DIFFERENT_GENDER_IDENTITY}
                stateManager={this.stateManager}
                otherQuestions
              />
              <AdditionalInformation
                key="additional-information"
                navId="additional-information"
                navTitle={'Additional Information'}
                stateManager={this.stateManager}
                cptState={this.stateManager.cpt.state}
              />
              <ConclusionStep
                key="conclusion"
                navId="conclusion"
                navTitle={'Conclusion'}
                person={getProband()}
              />
            </Navigation>
          </div>
        </TypeContext.Provider>
      </SessionTimeout>
    )
  }

  componentDidMount() {
    const {
      setThankYou,
      setLocalization,
      loggingIn,
      setLoggingIn,
      setAuthenticated,
      isAuthenticated,
    } = this.context

    loadFromBackend({
      setState: this.stateManager.setState,
      metrics: this.state.metrics,
      // Not meaningful to set setThankYou and setLocalization in App.js
      setLocalization,
      setThankYou,
      setAuthenticated,
      isAuthenticated,
    })

    // When refreshing browser, loggingIn is always true.
    // It is not because the login is done already in the first login process.
    // It should be false.
    if (loggingIn) {
      setLoggingIn(() => false)
    }
  }

  /**
   * `ref` from App.js is not working because
   * it is a class component. So the state lifting should be implemented
   */
  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) !== JSON.stringify(this.setState)) {
      this.context.setAppState(this.state)
    }
  }

  /**
   * Returns a formatted array of components that Navigation will render as a sidebar Folder
   * @param {String} id Folder component key
   * @param {String} label The label text that will show in the sidebar
   * @param {Array<ReactDOM>} components List of steps that will be in the folder
   * @returns {Array}
   **/
  renderFolder(id, label, components) {
    return [[{ id, label, type: 'folder' }, ...components]]
  }

  renderFamilySteps() {
    const steps = []

    const probandResolver = (shouldCreate, state) => {
      return QuestionnaireStateManager.getProband(state)[PersonFieldPaths.ID]
    }
    const motherResolver = (shouldCreate, state) => {
      const familyHelper = new FamilyHelper(state)

      return familyHelper
        .getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('F', shouldCreate, 'probandsMother').id
    }
    const fatherResolver = (shouldCreate, state) => {
      const familyHelper = new FamilyHelper(state)

      return familyHelper
        .getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('M', shouldCreate, 'probandsFather').id
    }
    const renderGrandparents = ({
      navTitle,
      idLabel,
      parent,
      parentSex,
      grandparent,
      grandparentSex,
    }) => {
      return this.renderPersonStep(navTitle, idLabel, grandparentSex, (shouldCreate, state) => {
        const familyHelper = new FamilyHelper(state)

        return familyHelper
          .getPerson(QuestionnaireStateManager.getProband(state))
          .resolveParent(parentSex, shouldCreate, parent)
          .resolveParent(grandparentSex, shouldCreate, grandparent).id
      })
    }

    const patientFamilySteps = [
      {
        id: 'patientsFamilyStep',
        label: 'Your Family',
        type: 'folder',
      },
      this.renderPatientsChildrenStep(),
      this.renderPersonSiblingCountsStep('Siblings', 'proband', probandResolver),
      this.renderPersonStep('Mother', 'mother', 'F', motherResolver),
      this.renderPersonStep('Father', 'father', 'M', fatherResolver),
    ]
    const mothersFamilyStep = [
      {
        id: 'mothersFamilyStep',
        label: "Your Mother's Family",
        type: 'folder',
      },
      this.renderPersonSiblingCountsStep("Your Mother's siblings", 'mother', motherResolver),
      renderGrandparents({
        navTitle: "Your Mother's Mother",
        idLabel: 'mat-gmother',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandmother',
        grandparentSex: 'F',
      }),
      renderGrandparents({
        navTitle: "Your Mother's Father",
        idLabel: 'mat-gfather',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]
    const fathersFamilyStep = [
      {
        id: 'fathersFamilyStep',
        label: "Your Father's Family",
        type: 'folder',
      },
      this.renderPersonSiblingCountsStep("Your Father's siblings", 'father', fatherResolver),
      renderGrandparents({
        navTitle: "Your Father's Mother",
        idLabel: 'pat-gmother',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandmother',
        grandparentSex: 'F',
      }),
      renderGrandparents({
        navTitle: "Your Father's Father",
        idLabel: 'pat-gfather',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]

    // TODO: Cousins
    // if (
    //   this.stateManager.getFamilyHelper()
    //     .getFirstCousins(probandResolver(false, this.state), 'F').length
    // ) {
    //   mothersFamilyStep.push(this.renderCousinStep('maternal'))
    // }

    // if (
    //   this.stateManager.getFamilyHelper()
    //     .getFirstCousins(probandResolver(false, this.state), 'M').length
    // ) {
    //   fathersFamilyStep.push(this.renderCousinStep('paternal'))
    // }

    steps.push(patientFamilySteps)

    // if mother of father of the patient are adopted then not render their family steps
    const isMotherAdopted = this.stateManager.getPersonValue(
      motherResolver(false, this.state),
      PersonFieldPaths.IS_ADOPTED,
    )
    const isFatherAdopted = this.stateManager.getPersonValue(
      fatherResolver(false, this.state),
      PersonFieldPaths.IS_ADOPTED,
    )

    if (isMotherAdopted !== 'Y') {
      steps.push(mothersFamilyStep)
    } else {
      _.forEach(
        this.stateManager.getFamilyHelper().getSiblings(motherResolver(false, this.state)),
        (sibling) => {
          this.stateManager.removePerson(sibling.person.id)
        },
      )
    }
    if (isFatherAdopted !== 'Y') {
      steps.push(fathersFamilyStep)
    } else {
      _.forEach(
        this.stateManager.getFamilyHelper().getSiblings(fatherResolver(false, this.state)),
        (sibling) => {
          this.stateManager.removePerson(sibling.person.id)
        },
      )
    }

    return steps
  }

  renderFamilyStep(
    isSiblingCountsStep,
    title,
    idLabel,
    resolvePersonId,
    customRenderProps,
    iconClassName,
    removePerson = false,
  ) {
    let id

    if (isSiblingCountsStep) {
      id = 'person-' + idLabel + '-siblings'
    } else {
      id = 'person-' + idLabel
    }
    const renderProps = {
      key: id,
      navId: id,
      navTitle: title,
      icon: iconClassName,
      stateManager: this.stateManager,
      targetPerson: idLabel,
      removePerson,
      persons: this.state.persons,
    }

    renderProps.resolvePersonId = resolvePersonId
    _.assign(renderProps, customRenderProps)
    if (isSiblingCountsStep) {
      return <PersonSiblingsStep {...renderProps} />
    } else {
      return <PersonStep {...renderProps} />
    }
  }

  renderPersonStep(title, idLabel, sex, resolvePersonId, renderProps) {
    if (sex) {
      renderProps = renderProps || {}
      renderProps.sex = sex
    }

    return this.renderFamilyStep(false, title, idLabel, resolvePersonId, renderProps, 'person')
  }

  renderPersonSiblingCountsStep(title, idLabel, resolvePersonId, relationshipPrefix) {
    return this.renderFamilyStep(
      true,
      title,
      idLabel,
      resolvePersonId,
      null,
      'people',
      relationshipPrefix,
    )
  }

  renderPersonSiblingStep(siblingObj, siblingOfId, siblingOfLabel, type) {
    const removeSibling = () => {
      return this.stateManager.removePerson(siblingObj.person.id)
    }

    return this.renderFamilyStep(
      false,
      Questionnaire.getSiblingNavTitle(siblingOfLabel, siblingObj),
      siblingObj.person.id,
      () => siblingObj.person.id,
      {
        type,
        siblingPersonId: siblingOfId,
        isHalfSibling: FamilyHelper.isHalfSibling(siblingObj),
        sex: _.get(siblingObj.person.getDetailsObj(), PersonFieldPaths.SEX),
        siblingOfLabel,
      },
      'person',
      removeSibling,
    )
  }

  renderPatientsChildrenStep() {
    const key = 'probands-children'

    return (
      <ChildInfoStep
        person={this.stateManager.getProband()}
        key={key}
        navId={key}
        icon="people"
        navTitle="Children"
        stateManager={this.stateManager}
        persons={this.state.persons}
      />
    )
  }

  renderCousinStep(side) {
    const key = side + '-cousins'
    const sideLabel = {
      paternal: 'Paternal',
      maternal: 'Maternal',
    }[side]

    return (
      <CousinInfoStep
        side={side}
        person={this.stateManager.getProband()}
        key={key}
        navId={key}
        icon="people"
        navTitle={sideLabel + ' cousins'}
        stateManager={this.stateManager}
      />
    )
  }
}

Questionnaire.getSiblingNavTitle = (subjectTitle, sibling) => {
  const isHalfSibling = FamilyHelper.isHalfSibling(sibling)
  const personDetails = sibling.person.getDetailsObj()

  const siblingSexes = {
    M: 'brother',
    F: 'sister',
  }[_.get(personDetails, PersonFieldPaths.SEX)]

  let returnSex = `${subjectTitle}'s ${isHalfSibling ? 'half-' : ''}${siblingSexes}`
  const firstName = _.get(personDetails, PersonFieldPaths.FIRST_NAME)
  const lastName = _.get(personDetails, PersonFieldPaths.LAST_NAME)

  if (firstName || lastName) {
    returnSex += ` ${String.fromCodePoint(8226)} ${firstName || ''}${
      lastName ? ' ' + lastName : ''
    }`
  }

  return returnSex
}

export default Questionnaire
