import { FormGroup, TextArea } from '@blueprintjs/core'
import React, { useState } from 'react'
import ConditionalSection from './ConditionalSection'

export default function RelatedByBlood({
  ancestryProperty,
  personId,
  stateManager,
  label,
  subLabel,
  bothParentMembers,
}) {
  let parentProperty

  if (bothParentMembers.length === 2) {
    // It a root generating the stateManager and FamilyHelper error.
    // Now it is scoped with if statement for workaround.
    // Then, we need to implement hard coding fo state update in the component level.
    // We need to implement this workaround when each page needs to
    // automatically create/update state value immediately after the page renders
    // without the user events.
    // stateManager and FamilyHelper state handling are not correctly working
    // because of Object mutability which are not compatible to React's state update.
    const { properties } = stateManager.getPartnership(personId)
    parentProperty = properties.find((item) => item.type === ancestryProperty)
  }

  const [condition, setCondition] = useState(parentProperty?.isPresent || null)
  const [relation, setRelation] = useState(parentProperty?.value || null)

  const setRelationship = (isPresent) => {
    stateManager.setPartnershipProps(personId, [
      {
        type: ancestryProperty,
        isPresent,
        value: '',
      },
    ])
    setCondition(isPresent)
  }

  const setRelationshipDesc = (desc) => {
    stateManager.setPartnershipProps(personId, [
      {
        type: ancestryProperty,
        isPresent: condition,
        value: desc,
      },
    ])
    setRelation(desc)
  }

  const setAshkenaziJewish = async (value) => {
    // cancerRisk property
    await stateManager.updatePersonValue({
      personId,
      path: ['cancerRiskData'],
      field: 'ashkenaziInheritance',
      newFieldValue: value === 'Y',
    })
  }

  const ashkenaziInheritance = () => {
    switch (stateManager.getPersonById(personId).cancerRiskData?.ashkenaziInheritance) {
      case true:
        return 'Y'
      case false:
        return 'N'
      default:
        return null
    }
  }

  return (
    <div className="consanguinityContainer">
      {ancestryProperty === 'ashkenaziJewish' ? (
        <ConditionalSection
          label={label}
          showWhenCondition="Y"
          conditionState={ashkenaziInheritance()}
          onConditionStateChange={(value) => setAshkenaziJewish(value)}
          orderSchema={['Y', 'N']}
          cleanUpChildQuestions={() => null}
        ></ConditionalSection>
      ) : (
        <ConditionalSection
          label={label}
          showWhenCondition="Y"
          conditionState={condition}
          onConditionStateChange={(value) => setRelationship(value)}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => setRelation('')}
        >
          {subLabel && (
            <FormGroup label={subLabel} helperText={''}>
              <TextArea
                maxLength="20000"
                onChange={(e) => setRelationshipDesc(e.target.value)}
                value={relation || ''}
              />
            </FormGroup>
          )}
        </ConditionalSection>
      )}
    </div>
  )
}
