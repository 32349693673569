import { FormGroup, Classes, NumericInput } from '@blueprintjs/core'
import React, { useContext, useRef } from 'react'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'

const { CAUSE_OF_DEATH, AGE_OF_DEATH } = PersonFieldPaths

const DeathDetails = ({ stateManager, personId, personIdWithCreate }) => {
  const { localization } = useContext(Context)
  const inputRef = useRef(null)

  const { causeOfDeath = '', ageOfDeath = '' } =
    stateManager.getState().persons[personId].lifeStatus || {}

  return (
    <React.Fragment>
      <FormGroup label={getLabel(localization, CAUSE_OF_DEATH)}>
        <input
          className={Classes.INPUT}
          type="text"
          dir="auto"
          value={causeOfDeath}
          onChange={({ target: { value } }) =>
            stateManager.setPersonValue(personIdWithCreate, CAUSE_OF_DEATH, value)
          }
        />
      </FormGroup>
      <FormGroup label={getLabel(localization, AGE_OF_DEATH)}>
        <NumericInput
          onButtonClick={(val) =>
            stateManager.setPersonValue(personIdWithCreate, AGE_OF_DEATH, val)
          }
          onValueChange={(val) => {
            // The max of this ageOfDeath is set up to 120.
            // So typing (onValueChange) also should not allow a number greater than 120.
            if (Number.isNaN(val) || val > 120) {
              return
            }

            // "0" value should be accepted because it is an age.
            stateManager.setPersonValue(
              personIdWithCreate,
              AGE_OF_DEATH,
              inputRef.current.value === '' ? inputRef.current.value : val,
            )
          }}
          value={ageOfDeath}
          minorStepSize={null}
          min={0}
          max={120}
          allowNumericCharactersOnly
          inputRef={inputRef}
        />
      </FormGroup>
    </React.Fragment>
  )
}

export default DeathDetails
