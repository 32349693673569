import { NonIdealState } from '@blueprintjs/core'
import React from 'react'

function Logout({ history }) {
  return (
    <div className="bp3-non-ideal-state">
      <NonIdealState
        icon="notifications"
        title={history.location.state?.message || 'You are not in the right page.'}
        // title="You have been logged out or we have not received your activity for a long time.
        // Please login again to restart the questionnaire."
        description={
          'To log in again,' +
          ' please paste the link provided to you by your service provider in the url bar.'
        }
      />
    </div>
  )
}

export default Logout
