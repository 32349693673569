import { NonIdealState } from '@blueprintjs/core'
import React from 'react'

export default function ThankYou({ history }) {
  const { externalUrl: { instructions = '', url = '', urlLabel = '' } = {} } =
    history.location.state || {}

  /**
   * Invoke `saveMetricBackend` to send the metric data.
   * @returns {Promise<undefined>}
   *
   * Todo: Still need to wait until
   * the backend resolves `Unauthorized` and `Session` issues.
   */
  // TODO: ref - PFQ-713 metric calls fail after form submission
  // const handleMetrics = async (event) => {
  //   event.preventDefault()

  // We do not need to update the `appState` in `Thank you` page
  // because the user can not get back to our app.
  //   return saveMetricToBackend(
  //     {
  //       ...appState.metrics,
  //       sourcePage: 'Thank you',
  //       destinationPage: 'Feedback',
  //       eventType: 'feedbackLink',
  //     },
  // It should be `true` because we need to send the session info
  // when the user clicks on the url in the Thank you page.
  //     true,
  //     () => {
  //       window.location.ref = url
  //     },
  //   )
  // }

  const description = (
    <>
      <p>
        Thank you for taking the time to fill out this questionnaire. Your Pre-visit questionnaire
        has been submitted.
      </p>
      <p>{instructions}</p>
      {url && urlLabel && (
        <a className="bp3-button bp3-intent-primary bp3-large" href={url}>
          {urlLabel}
        </a>
      )}
    </>
  )

  return (
    <div>
      <NonIdealState
        icon="saved"
        title={'Thank you for submitting!'}
        description={description}
        className="thankYou"
      />
    </div>
  )
}
