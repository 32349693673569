import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import React, { useState } from 'react'
import './App.css'
import Routes from './Routes'
import Context from './utils/context/Context'
import { getErrorMessage } from './utils/helpers'

function App() {
  const [demoMode, setDemoMode] = useState(true)
  const [loading, setLoading] = useState({})
  const [toasts, setToasts] = useState([])
  const [localization, setLocalization] = useState({})
  const [dialogBox, setDialogBox] = useState({ show: false })
  const [loggingIn, setLoggingIn] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [timeoutModal, setTimeoutModal] = useState({ type: '', open: false })
  const [thankYou, setThankYou] = useState({})
  const [appState, setAppState] = useState({})
  const [token, setToken] = useState('')

  const addToast = (statusId, intent, message) => {
    const toast = {
      id: statusId,
      intent,
      message,
    }

    setToasts([...toasts, toast])
  }

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        demoMode,
        localization,
        setDemoMode,
        dialogBox,
        setDialogBox,
        setLoggingIn,
        isAuthenticated,
        setAuthenticated,
        getErrorMessage,
        loggingIn,
        addToast,
        timeoutModal,
        setTimeoutModal,
        setAppState,
        appState,
        setThankYou,
        thankYou,
        setLocalization,
        token,
        setToken,
        setToasts,
        toasts,
      }}
    >
      <div className="App">
        <Routes />
      </div>
    </Context.Provider>
  )
}

export default App
