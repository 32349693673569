import { NonIdealState } from '@blueprintjs/core'
import React, { useContext, useEffect } from 'react'
import Context from '../utils/context/Context'

function Message({ history }) {
  const { setLoggingIn, loggingIn } = useContext(Context)

  useEffect(() => {
    if (!loggingIn) {
      setLoggingIn(() => true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { errs = [] } = history.location.state || {}

  let specificMessage = null

  if (errs.length > 1) {
    specificMessage = (
      <ul className="errorMessages">
        {errs.map((err, index) => (
          <li key={index}>
            <p>{err}</p>
          </li>
        ))}
      </ul>
    )
  } else if (errs.length === 1) {
    specificMessage = <p className="errorMessage">{errs[0]}</p>
  }

  return (
    <div className="bp3-non-ideal-state">
      <NonIdealState
        icon="info-sign"
        title={
          specificMessage
            ? 'Sorry, unable to Load Questionnaire.'
            : 'Sorry, you seem to be in the wrong page. Please, go back.'
        }
        description={
          specificMessage ? 'We cannot load the questionnaire, some possible causes are: ' : null
        }
      />
      <div>{specificMessage}</div>
    </div>
  )
}

export default Message
