export const PERSON_CONDITIONS = [
  {
    label: 'Endometriosis',
    id: 'HP:0030127',
    type: 'phenotype',
  },
  {
    label: 'Chronic pancreatitis that lasted longer than 6 months',
    id: 'HP:0006280',
    type: 'phenotype',
  },
  {
    label: 'Tumour or growth in the pituitary, parathyroid or adrenal gland',
    id: 'tumour',
    type: 'phenotype',
  },
  {
    label: 'More than 50 moles (not freckles)',
    id: 'moleNevi',
    type: 'phenotype',
  },
  {
    label: 'More than 10 polyps removed from the bowel (colon or rectum)',
    id: 'polypsRemoved',
    type: 'phenotype',
  },
  {
    label: 'Lobular carcinoma in situ (LCIS)',
    id: 'HP:0030076',
    type: 'phenotype',
  },
]
