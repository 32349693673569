import { FormGroup, InputGroup } from '@blueprintjs/core'
import React, { useContext } from 'react'
import Context from '../utils/context/Context'

import { getLabel } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
const { FIRST_NAME, LAST_NAME, LAST_NAME_AT_BIRTH } = PersonFieldPaths

function FullName({ getValue, setValue, onBlur, id, personIdWithCreate, sex, type, disabled }) {
  const { localization } = useContext(Context)
  const isProband = type === 'proband'

  return (
    <React.Fragment>
      <div className="fullNameGroup clearfix">
        <FormGroup
          label={getLabel(localization, FIRST_NAME) + (isProband ? '*' : '')}
          className="nameField"
        >
          <InputGroup
            disabled={disabled || false}
            value={getValue(id, FIRST_NAME) || ''}
            intent={isProband ? 'warning' : 'none'}
            onChange={(e) => setValue(personIdWithCreate, FIRST_NAME, e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label={getLabel(localization, LAST_NAME) + (isProband ? '*' : '')}
          className="nameField"
        >
          <InputGroup
            disabled={disabled || false}
            value={getValue(id, LAST_NAME) || ''}
            intent={isProband ? 'warning' : 'none'}
            onChange={(e) => setValue(personIdWithCreate, LAST_NAME, e.target.value)}
            onBlur={() => onBlur && onBlur()}
          />
        </FormGroup>
      </div>
      {sex === 'F' && (
        <FormGroup label={getLabel(localization, LAST_NAME_AT_BIRTH)}>
          <InputGroup
            value={getValue(id, LAST_NAME_AT_BIRTH) || ''}
            onChange={(e) => setValue(personIdWithCreate, LAST_NAME_AT_BIRTH, e.target.value)}
            onBlur={() => onBlur && onBlur()}
          />
        </FormGroup>
      )}
    </React.Fragment>
  )
}

export default FullName
