import { useEffect } from 'react'
import { login } from '../utils/persistance'

const AuthHooks = ({ token, setAuthenticated, setLoggingIn, setToken, history }) => {
  // The login call must not be in App.js file
  // because it should not be called again after login id done
  // unless the idle timer is invoked.
  useEffect(() => {
    login(
      () => {
        setToken('')
        setAuthenticated(() => true)
        setLoggingIn(() => false)
      },
      () => {
        setAuthenticated(() => false)
        setLoggingIn(() => true)
      },
      // Please do not mix up setAuthenticated and navigate calls.
      // They should be synchronized functions in sequence.
      () => {
        history.push('/questionnaire')
      },
      token,
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export default AuthHooks
