import { Spinner } from '@blueprintjs/core'
import React from 'react'

const Loader = ({ spinnerMessage }) => {
  return (
    <div className="spinner">
      <Spinner />
      <h4 className="spinner-text">{spinnerMessage}</h4>
    </div>
  )
}

export default Loader
