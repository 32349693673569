import { Alert, Button, Intent } from '@blueprintjs/core'
import _ from 'lodash'
import React, { Component } from 'react'
import uuidv4 from 'uuid/v4'
import { getFullName } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import InternalCard from './InternalCard'

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

class MultiPersonEntry extends Component {
  constructor(props) {
    super(props)

    this.state = {
      personEntryIds:
        this.props.personIds && this.props.personIds.length ? _.clone(this.props.personIds) : [],
      isLastDefaultOpened: false,
      isRemoveAlertOpen: false,
      personIdToRemove: null,
    }
  }

  render() {
    const personIds = this.isControlledComponent()
      ? this.props.personIds
      : this.state.personEntryIds
    const personIdsLength = personIds.length

    return (
      <React.Fragment>
        {personIds.map((personId, i) => (
          <InternalCard
            key={personId}
            opened={
              this.props.focusedPersonId === personId ||
              (i === personIdsLength - 1 && this.state.isLastDefaultOpened)
            }
            fullName={getFullName(
              this.props.getValue(personId, FIRST_NAME),
              this.props.getValue(personId, LAST_NAME),
            )}
            relationship={this.props.personLabel(personId)}
            removePersonEntry={_.partial(this.removePersonEntry, personId)}
            medicalConditions={this.getMedicalConditions(personId)}
          >
            {this.props.entryRenderer(personId)}
          </InternalCard>
        ))}
        {this.props.addAndRemoveEntries && (
          <Button
            icon="add"
            text="Select relative"
            minimal={true}
            intent="primary"
            className="addButton"
            onClick={this.addPersonEntry}
          />
        )}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          intent={Intent.DANGER}
          isOpen={this.state.isRemoveAlertOpen}
          onCancel={() => this.setState({ isRemoveAlertOpen: false, personIdToRemove: null })}
          onConfirm={this.confirmRemovePersonEntry}
        >
          <p>Are you sure you want to remove?</p>
        </Alert>
      </React.Fragment>
    )
  }

  removePersonEntry = (personId) => {
    this.setState({ personIdToRemove: personId, isRemoveAlertOpen: true })
  }

  confirmRemovePersonEntry = () => {
    const { personIdToRemove } = this.state

    if (this.isControlledComponent()) {
      this.props.onRemovePersonEntry(personIdToRemove)
      this.setState({ isRemoveAlertOpen: false, personIdToRemove: null })

      return
    }

    this.setState((state) => {
      state.personEntryIds = _.without(state.personEntryIds, personIdToRemove)
      state.isRemoveAlertOpen = false
      state.personIdToRemove = null

      return { ...state }
    })

    this.props.stateManager.removePerson(personIdToRemove)
  }

  addPersonEntry = () => {
    if (this.isControlledComponent()) {
      this.setState({ isLastDefaultOpened: true }, () => {
        return this.props.onAddPersonEntry()
      })
    }

    this.setState((state) => {
      state.personEntryIds = [...state.personEntryIds, uuidv4()]
      state.isLastDefaultOpened = true

      return state
    })
  }

  isControlledComponent = () => {
    return _.isFunction(this.props.onAddPersonEntry) && _.isFunction(this.props.onRemovePersonEntry)
  }

  getMedicalConditions = (cousinId) => {
    const lifeStatus = this.props.stateManager.getPersonValue(
      cousinId,
      PersonFieldPaths.ALIVE_STATUS,
    )
    const heart = this.props.stateManager.getPersonValue(
      cousinId,
      PersonFieldPaths.CONDITION_IS_PRESENT,
      { conditionName: 'heart' },
    )
    const medicalConditions = [
      { status: heart === 'Y', id: 'heart', description: 'Person has Heart Condition' },
      { status: lifeStatus === 'N', id: 'deceased', description: 'Person is deceased' },
    ]

    return medicalConditions
  }
}

export default MultiPersonEntry
