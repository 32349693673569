import { Icon } from '@blueprintjs/core'
import React, { forwardRef } from 'react'
import Controls from './Controls'

const CurrentStep = forwardRef(
  (
    {
      cptState,
      setState,
      addToast,
      icon,
      navTitle,
      currentStep,
      loading,
      handleMovePrevious,
      canMovePrevious,
      isLastStep,
      saveAndMoveToNext,
      setSubmitWarning,
      btnLabels,
    },
    ref,
  ) => {
    return (
      <div
        key="curr"
        ref={ref}
        id="questionnaire-current-step"
        className="bp3-card bp3-elevation-0"
      >
        <div className="cardTitle">
          <div className="d-flex align-items-center">
            <Icon icon={icon} color="#082B45" iconSize="14" className="cardTitleIcon" />
            <span>
              <h4>{navTitle}</h4>
            </span>
          </div>
        </div>
        <div className="cardBody">{currentStep}</div>
        <Controls
          demoMode={false} // change to true for testing
          cptState={cptState}
          setState={setState}
          addToast={addToast}
          key="controls"
          loading={loading}
          handleMovePrevious={handleMovePrevious}
          canMovePrevious={canMovePrevious}
          isLastStep={isLastStep}
          saveAndMoveToNext={saveAndMoveToNext}
          setSubmitWarning={setSubmitWarning}
          btnLabels={btnLabels}
        />
      </div>
    )
  },
)

export default CurrentStep
