import { Button, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import React, { useState, forwardRef, useImperativeHandle } from 'react'

// Need to use `forwardRef` not to affect the other parent components.
const DropdownSelector = forwardRef(
  (
    { defaultLabel, items, onItemSelect, noResultsOption = undefined, savedValue = undefined },
    ref,
  ) => {
    const [selectedOption, setSelectedOption] = useState(savedValue || undefined)

    const renderSelectItems = (item, { handleClick, modifiers }) => {
      if (!modifiers.matchesPredicate) {
        return null
      }

      return (
        <MenuItem
          active={item.label === selectedOption}
          disabled={modifiers.disabled}
          key={item.id}
          onClick={handleClick}
          text={item.label}
        />
      )
    }

    const filterSelectItems = (query, type) => {
      return !query || type.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
    }

    useImperativeHandle(ref, () => ({
      deleteSelectedValue: () => setSelectedOption(),
    }))

    return (
      <Select
        items={items}
        itemPredicate={filterSelectItems}
        itemRenderer={renderSelectItems}
        noResults={
          noResultsOption ? (
            <MenuItem
              text={noResultsOption.label}
              onClick={() => {
                onItemSelect(noResultsOption)
                setSelectedOption(noResultsOption.label)
              }}
            />
          ) : (
            <MenuItem disabled={true} text="No results." />
          )
        }
        onItemSelect={(e) => {
          onItemSelect(e)
          setSelectedOption(e.label)
        }}
      >
        <Button
          text={selectedOption || defaultLabel}
          rightIcon="caret-down"
          className="cancerType"
          fill={true}
        />
      </Select>
    )
  },
)

export default DropdownSelector
