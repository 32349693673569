import { FormGroup, NumericInput } from '@blueprintjs/core'
import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState } from 'react'

import Context from '../utils/context/Context'
import { getLabel, isOnlyDigits } from '../utils/helpers'

const genericInputProps = {
  allowNumericCharactersOnly: true,
  buttonPosition: 'none',
  fill: false,
  large: false,
  selectAllOnFocus: true,
  className: 'dobInput',
}

const DATE_VALUE_TYPE = {
  YEAR_TYPE: 'year',
  MONTH_TYPE: 'month',
  DAY_TYPE: 'day',
}

function DOBComponent({ stateManager, dobDateStr, onChange, onBlur, disabled }) {
  const { localization } = useContext(Context)
  const { YEAR_TYPE, MONTH_TYPE, DAY_TYPE } = DATE_VALUE_TYPE
  const dobDate = dobDateStr ? moment.utc(dobDateStr) : null
  const dobYear = dobDate ? moment(dobDate).format('YYYY') : ''
  const dobMonth = dobDate ? moment(dobDate).format('MM') : ''
  const dobDay = dobDate ? moment(dobDate).format('DD') : ''
  const [year, setYear] = useState(dobYear)
  const [month, setMonth] = useState(dobMonth)
  const [day, setDay] = useState(dobDay)
  const [isError, setIsError] = useState(false)

  const handleChange = (dateValueType) => (_v, value) => {
    if (validateInput(value)) {
      if (dateValueType === YEAR_TYPE) {
        setYear(value)
      } else if (dateValueType === MONTH_TYPE) {
        setMonth(value)
      }
      if (dateValueType === DAY_TYPE) {
        setDay(value)
      }
    }
  }
  const DOBElementInputs = ({ place, value, onValueChange }) => {
    return (
      <NumericInput
        {...genericInputProps}
        disabled={disabled}
        placeholder={place}
        value={value}
        onValueChange={handleChange(onValueChange)}
        onBlur={handleDateBlur}
      />
    )
  }

  const validateInput = (value) => isOnlyDigits(value) || _.isEmpty(value)
  const setZeroDigitForSingleDigit = (value) => (value.length === 1 ? `0${value}` : value)
  const handleDateBlur = () => {
    setIsError(false)
    if (!_.isEmpty(year) && !_.isEmpty(month) && !_.isEmpty(day)) {
      const dateString = `${year}-${month}-${day}`
      const dateMoment = moment.utc(dateString, 'YYYY-MM-DD')

      if (dateMoment.isValid() && dateMoment.isSameOrBefore(moment.utc())) {
        onChange(dateMoment)
        // keep two digits for month and day
        setMonth(setZeroDigitForSingleDigit(month))
        setDay(setZeroDigitForSingleDigit(day))
      } else {
        // Reset values
        setYear(dobYear)
        setMonth(dobMonth)
        setDay(dobDay)

        setIsError(true)
      }
    }
    onBlur && onBlur()
  }

  const isGBR = stateManager.getCountryCode() === 'GBR'

  return (
    <FormGroup
      label={
        <div>
          {getLabel(localization, 'dateOfBirth')}{' '}
          <span className="dobFormatText">{isGBR ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}</span>
        </div>
      }
      className="dobContainer"
    >
      <div className="inputs">
        {isGBR ? (
          <>
            {DOBElementInputs({ place: 'Day', value: day, onValueChange: DAY_TYPE })}
            {DOBElementInputs({ place: 'Month', value: month, onValueChange: MONTH_TYPE })}
            {DOBElementInputs({ place: 'Year', value: year, onValueChange: YEAR_TYPE })}
          </>
        ) : (
          <>
            {DOBElementInputs({ place: 'Year', value: year, onValueChange: YEAR_TYPE })}
            {DOBElementInputs({ place: 'Month', value: month, onValueChange: MONTH_TYPE })}
            {DOBElementInputs({ place: 'Day', value: day, onValueChange: DAY_TYPE })}
          </>
        )}
      </div>
      {isError && <span className="error">Invalid date of birth. Resetting the date.</span>}
    </FormGroup>
  )
}

export default DOBComponent
